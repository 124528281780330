<template>
  <div class="group">
    <div class="title">
      <div class="logo" @click="gohome"></div>
      <div class="act">
        <div class="act_l phone" @click="menuopen(true)">
          <svg t="1703488092285" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M96 256h832c19.2 0 32-12.8 32-32s-12.8-32-32-32H96c-19.2 0-32 12.8-32 32s12.8 32 32 32zM928 480H96c-19.2 0-32 12.8-32 32s12.8 32 32 32h832c19.2 0 32-12.8 32-32s-12.8-32-32-32zM928 768H96c-19.2 0-32 12.8-32 32s12.8 32 32 32h832c19.2 0 32-12.8 32-32s-12.8-32-32-32z"
              p-id="2344" fill="#ffffff"></path>
          </svg>
        </div>
        <div class="act_l" v-if="lo" @click="out">

          <svg t="1704349325864" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M512 960C273.8 960 80 766.2 80 528c0-143.7 71.2-277.6 190.4-358.2l35.8 53C204.7 291.5 144 405.6 144 528c0 202.9 165.1 368 368 368s368-165.1 368-368c0-125.4-62.9-241-168.4-309.2l34.8-53.7C870.1 245.1 944 380.8 944 528c0 238.2-193.8 432-432 432z"
              p-id="2345" fill="#ffffff"></path>
            <path d="M480 80h64v416h-64z" p-id="2346" fill="#ffffff"></path>
          </svg>
        </div>
        <div class="act_l" v-if="!lo" @click="user.open = true">
          <svg t="1703318592891" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M858.5 763.6c-18.9-44.8-46.1-85-80.6-119.5-34.5-34.5-74.7-61.6-119.5-80.6-0.4-0.2-0.8-0.3-1.2-0.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-0.4 0.2-0.8 0.3-1.2 0.5-44.8 18.9-85 46-119.5 80.6-34.5 34.5-61.6 74.7-80.6 119.5C146.9 807.5 137 854 136 901.8c-0.1 4.5 3.5 8.2 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c0.1 4.4 3.6 7.8 8 7.8h60c4.5 0 8.1-3.7 8-8.2-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"
              p-id="2324" fill="#ffffff"></path>
          </svg>
        </div>
        <div class="act_l" @click="setLan">
          {{ lan == '0' ? 'EN' : 'CN' }}
        </div>
        <div class="act_l" @click="search.open = true">
          <svg t="1703318818067" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M953.504 908.256l-152.608-163.296c61.856-74.496 95.872-167.36 95.872-265.12 0-229.344-186.624-415.968-416.032-415.968-229.344 0-415.968 186.592-415.968 415.968s186.624 415.968 416 415.968c60.096-0.032 118.048-12.576 172.224-37.248 16.096-7.328 23.2-26.304 15.872-42.368-7.328-16.128-26.4-23.264-42.368-15.872-45.856 20.864-94.88 31.456-145.76 31.488-194.08 0-351.968-157.888-351.968-351.968 0-194.048 157.888-351.968 351.968-351.968 194.112 0 352.032 157.888 352.032 351.968 0 91.36-34.848 177.92-98.08 243.744-12.256 12.736-11.84 32.992 0.864 45.248 0.96 0.928 2.208 1.28 3.296 2.08 0.864 1.28 1.312 2.752 2.4 3.904l165.504 177.088c6.272 6.752 14.816 10.144 23.36 10.144 7.84 0 15.68-2.848 21.856-8.64C964.864 941.408 965.568 921.152 953.504 908.256z"
              p-id="3400" fill="#ffffff"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>{{ lan == '0'
        ? '南京协澳智能控制系统有限公司版权所有 未经许可不得擅自复制、发放、使用'
        : "Copyright of Nanjing ODES Control" }}</p>
      <p>Copyright © {{  new Date().getFullYear() }} ODES <a href="http://beian.miit.gov.cn/">苏ICP备13001438号-2</a></p>
    </div>
    <div :class="`nav ${menu ? 'open' : ''} ${chosePro.show ? 'pro' : ''}`">
      <div :class="`nav_l ${nav == 0 ? 'act' : ''}`" @click="setNav(0)">{{ lan == '0' ? '首页' : 'Home' }}</div>
      <div :class="`nav_l ${nav == 1 ? 'act' : ''}`" @click="setNav(1)">{{ lan == '0' ? '解决方案' : 'Solution' }}</div>
      <div :class="`nav_l ${nav == 2 ? 'act' : ''}`" @click="setNav(2)">{{ lan == '0' ? '产品大全' : 'Product' }}</div>
      <div :class="`nav_l ${nav == 3 ? 'act' : ''}`" @click="setNav(3)">{{ lan == '0' ? '关于我们' : 'About Us' }}</div>
      <div :class="`nav_l ${nav == 4 ? 'act' : ''}`" @click="setNav(4)">{{ lan == '0' ? '联系合作' : 'Contact' }}</div>
      <div :class="`nav_l ${nav == 5 ? 'act' : ''}`" @click="setNav(5)">{{ lan == '0' ? '加入我们' : 'Join Us' }}</div>
    </div>
    <div :class="`product-t ${chosePro.show ? 's' : ''}`" @mousewheel.stop="scmove3">
      <template v-for="vv in prodDeArr">
        <template v-if="vv.productSerieId == chosePro.fa.productSerieId">
          <div class="product-t_l active">
            <span></span>
            <p>{{ vv.name }}</p>
          </div>
          <template v-for="v in chosePro.fa.products">
            <div :class="`product-t_l sm ${chosePro.data.productId == v.productId ? 'active' : ''}`"
              @click="choseProduct(v)">
              <span></span>
              <p>{{ v.name }}</p>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="product-t_l" @click="showProduct(true, vv)">
            <span></span>
            <p>{{ vv.name }}</p>
          </div>
        </template>
      </template>
    </div>
    <div class="content">
      <Swiper :modules="modules" :mousewheel="chosePro.show ? false : true" @slideChange="change" @swiper="onSwiper"
        ref="swiper" :slides-per-view='1' class="content-swiper" direction='vertical'>
        <SwiperSlide @touchmove.stop @mousewheel.stop="scmove">
          <div :class="`page-in ${nav == 0 ? page1p == 0 ? 'act1' : 'act2' : ''}`">
            <div class="page-in_content max">
              <div :class="`p1 ${lan == '0' ? '' : 'e1'}`"></div>
              <div class="p2"></div>
              <div :class="`p1-1 ${lan == '0' ? '' : 'e1'}`"></div>
              <div :class="`p1-2 ${lan == '0' ? '' : 'e1'}`"></div>
              <div class="p-pon">
                <div :class="`p-pon_l ${page1p == 0 ? 'act' : ''}`" @click="setPon('page1p', 0)"></div>
                <div :class="`p-pon_l ${page1p == 1 ? 'act' : ''}`" @click="setPon('page1p', 1)"></div>
              </div>
              <div class="pagep l" @click="setPon('page1p', 0)">
                <svg t="1704183948313" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M874.666667 480H224L514.133333 170.666667c12.8-12.8 10.666667-34.133333-2.133333-44.8s-32-10.666667-44.8 2.133333l-341.333333 362.666667c-2.133333 2.133333-4.266667 6.4-6.4 8.533333-2.133333 4.266667-2.133333 6.4-2.133334 10.666667s0 8.533333 2.133334 10.666666c2.133333 4.266667 4.266667 6.4 6.4 8.533334l341.333333 362.666666c6.4 6.4 14.933333 10.666667 23.466667 10.666667 8.533333 0 14.933333-2.133333 21.333333-8.533333 12.8-12.8 12.8-32 2.133333-44.8L224 544H874.666667c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32z"
                    fill="#ffffff" p-id="3620"></path>
                </svg>
              </div>
              <div class="pagep r" @click="setPon('page1p', 1)">
                <svg t="1704184166782" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M904.533333 522.666667c2.133333-4.266667 2.133333-6.4 2.133334-10.666667s0-8.533333-2.133334-10.666667c-2.133333-4.266667-4.266667-6.4-6.4-8.533333l-341.333333-362.666667c-12.8-12.8-32-12.8-44.8-2.133333-12.8 12.8-12.8 32-2.133333 44.8l290.133333 309.333333H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h650.666667L509.866667 853.333333c-12.8 12.8-10.666667 34.133333 2.133333 44.8 6.4 6.4 14.933333 8.533333 21.333333 8.533334 8.533333 0 17.066667-4.266667 23.466667-10.666667l341.333333-362.666667c2.133333-2.133333 4.266667-6.4 6.4-10.666666z"
                    fill="#ffffff" p-id="3876"></path>
                </svg>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide @touchmove.stop>
          <div :class="`page-in ${nav == 1 ? 'act' : ''}`">
            <h3>{{ lan == '0' ? '解决方案' : 'Solution' }}
              <h4>{{ lan == '0' ? 'Solution' : '' }}</h4>
            </h3>
            <div class="page-in_content">
              <div class="p3 pc p3-pc" @mousewheel.stop="scmove">
                <div class="p3l" v-if="solArr.length >= 1" @click="openSol(solArr[0])">
                  <p> {{ solArr[0].name }}</p>
                  <img :src="`${baseUrl}${solArr[0].coverPicture}`" />
                </div>
                <div class="p3_l" v-for="( item, index ) in  Math.ceil((solArr.length - 1) / 2) ">
                  <div class="p3l" @click="openSol(solArr[index * 2 + 1])">
                    <p>{{ solArr[index * 2 + 1].name }}</p>
                    <img :src="`${baseUrl}${solArr[index * 2 + 1].coverPicture}`" />
                  </div>
                  <div class="p3l" v-if="solArr.length - 1 >= index * 2 + 2" @click="openSol(solArr[index * 2 + 2])">
                    <p>{{ solArr[index * 2 + 2].name }}</p>
                    <img :src="`${baseUrl}${solArr[index * 2 + 2].coverPicture}`" />
                  </div>
                </div>
              </div>
              <div class="p3 phone" @touchmove.stop>
                <div class="p3_l" v-for="( item, index ) in  solArr " @click="openSol(item)">
                  <div class="p3l">
                    <p>{{ item.name }}</p>
                    <img :src="`${baseUrl}${item.coverPicture}`" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide @touchmove.stop>
          <div :class="`page-in ${nav == 2 ? chosePro.show ? '' : 'act' : ''}`">
            <h3>{{ lan == '0' ? '产品大全' : 'Product Catalog' }}
              <h4>{{ lan == '0' ? 'Product Catalog' : '' }}</h4>
            </h3>
            <div class="page-in_content">
              <div class="p4">
                <template v-for="    item, index     in     prodArr    ">
                  <div :class="`p4_l ${index == hover ? 'act' : ''}`" @mouseover="hoverThis(index)"
                    @click="hoverThis(index)">
                    <div class="p4-t">
                      <p>{{ item.name }}</p>
                      <p v-if="lan == '0'">{{ item.en }}</p>
                    </div>
                    <img :src="`${baseUrl}${item.coverPicture}`" />
                    <div class="info">
                      <p v-if="index != hover">{{ index == hover ? item.remark : item.name }}</p>
                      <p v-if="lan == '0'">{{ index == hover ? item.remark : item.en }}</p>
                      <!-- <p v-if="index != hover">{{ item.en }}</p> -->
                    </div>
                    <div class="active">
                      <div class="btn" @click="showProduct(true, item)">{{ lan == '0' ? '查看系列' : 'View series' }}</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="tool">
                <div :class="`tool_l ${prodIndex <= 0 ? 'dis' : ''}`" @click="toPage(prodIndex <= 0 ? 0 : prodIndex - 1)">
                </div>
                <div :class="`tool_l r ${prodIndex >= prodAll - 1 ? 'dis' : ''}`"
                  @click="toPage(prodIndex >= prodAll - 1 ? (prodAll - 1) : (prodIndex + 1))"></div>
              </div>
            </div>
          </div>
          <div :class="`page-in ${nav == 2 ? chosePro.show ? 'act inner' : '' : ''}`" @touchmove.stop @wheel.stop>
            <div class="page-in_content">
              <div class="p4-2">
                <template v-for="v in chosePro.fa.products">
                  <div class="p4-2_l" @click="choseProduct(v)">
                    <img :src="`${baseUrl}${v.coverPicture}`" />
                    <p style="
display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;">
                      {{ v.name }}</p>
                  </div>
                </template>
              </div>
              <div class="p4-1">
                <div class="return" @click="showProduct(false)">
                  <svg t="1704710248871" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M624 288H236.8l105.6-105.6c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0l-160 160c-3.2 3.2-6.4 6.4-6.4 9.6-3.2 9.6-3.2 16 0 25.6 3.2 3.2 3.2 6.4 6.4 9.6l160 160c6.4 6.4 12.8 9.6 22.4 9.6s16-3.2 22.4-9.6c12.8-12.8 12.8-32 0-44.8L236.8 352h387.2c115.2 0 208 92.8 208 208S739.2 768 624 768H252.8c-19.2 0-32 12.8-32 32s12.8 32 32 32h371.2c150.4 0 272-121.6 272-272S774.4 288 624 288z"
                      p-id="2266" fill="#ffffff"></path>
                  </svg>
                </div>
                <div class="p4-1_t">
                  <div :class="`p4-1_t_l ${chosePro.index == 0 ? 'active' : ''}`" @click="chosePro.index = 0">{{ lan ==
                    '0'
                    ? '产品应用场景与特点' : 'Application scenarios and characteristics' }}
                  </div>
                  <div :class="`p4-1_t_l ${chosePro.index == 1 ? 'active' : ''}`" @click="chosePro.index = 1">{{ lan ==
                    '0'
                    ? '命名规则与推荐型号' : 'Rules and models' }}
                  </div>
                  <div :class="`p4-1_t_l ${chosePro.index == 2 ? 'active' : ''}`" @click="chosePro.index = 2">{{ lan ==
                    '0'
                    ? '技术参数' : 'Technical parameters' }}</div>
                  <div :class="`p4-1_t_l ${chosePro.index == 3 ? 'active' : ''}`" @click="chosePro.index = 3">{{ lan ==
                    '0'
                    ? '端子定义' : 'Definition of terminal' }}</div>
                  <div :class="`p4-1_t_l ${chosePro.index == 4 ? 'active' : ''}`" @click="chosePro.index = 4">{{ lan ==
                    '0'
                    ? '典型应用原理图' : 'Schematic diagram' }}</div>
                  <div :class="`p4-1_t_l ${chosePro.index == 5 ? 'active' : ''}`" @click="chosePro.index = 5">{{ lan ==
                    '0'
                    ? '安装尺寸图' : 'Installation dimension diagram' }}</div>
                  <div :class="`p4-1_t_l ${chosePro.index == 6 ? 'active' : ''}`" @click="chosePro.index = 6">{{ lan ==
                    '0'
                    ? '解决方案' : 'Solution Proposal' }}</div>
                  <div :class="`p4-1_t_l ${chosePro.index == 7 ? 'active' : ''}`" @click="chosePro.index = 7">{{ lan ==
                    '0'
                    ? '产品文档' : 'Product documentation' }}</div>
                </div>
                <div class="p4-1_b" @mousewheel.stop="scmove2">
                  <template v-if="chosePro.index == 0 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 0">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 1 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 1">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 2 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 2">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 3 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 3">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 4 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 4">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 5 && chosePro.data">
                    <template v-for="f in chosePro.data.productImages">
                      <template v-if="f.imageType == 5">
                        <img v-for="c in f.images" :src="`${baseUrl}${c}`" />
                      </template>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 6 && chosePro.data">
                    <template v-for="f in chosePro.data.solutions">
                      <div class="sol-list" @click="toSol(f)">
                        <p>{{ f.name }}</p>
                        <img :src="`${baseUrl}${f.coverPicture}`" />
                      </div>
                    </template>
                  </template>
                  <template v-if="chosePro.index == 7 && chosePro.data">
                    <templage v-if="lo">
                      <div class="downlist" style="margin: 2rem auto 0.5rem auto;background: #3639b819">
                        <div class="div">{{ lan == '0' ? '文件名' : 'Name' }}</div>
                        <div class="div">{{ lan == '0' ? '操作' : 'Action' }}</div>
                      </div>
                      <template v-for="f in chosePro.data.productFiles">
                        <div class="downlist">
                          <div class="div">{{ f.fileName }}</div>
                          <div class="div">
                            <a-button size="small" @click="down(f)">{{ lan == '0' ? '下载' :
                              'Download' }}</a-button>
                            <a-button v-if="f.fileName.indexOf('.pdf') > -1" style="margin-left: 0.5rem" size="small"
                              @click="pdf(f)">{{ lan == '0' ? '预览' :
                                'Preview'
                              }}</a-button>
                          </div>
                        </div>
                      </template>
                    </templage>
                    <template v-else>
                      <p style="cursor: pointer" @click="user.open = true"><span style="color:blue">【{{ lan == '0' ? '登录'
                        :
                        'Login' }}】</span>{{ lan == '0' ? '查看更多内容' :
    'in to view more content' }}</p>
                    </template>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide @touchmove.stop @mousewheel.stop="scmove1">
          <div
            :class="`page-in  ${nav == 3 ? page4p == 0 ? 'act1' : page4p == 1 ? 'act2' : page4p == 2 ? 'act3' : 'act4' : ''}`">
            <h3>{{ page4p == 0 ?
              (lan == '0' ? '关于我们' : 'About Us') :
              page4p == 1 ?
                (lan == '0' ? '企业历程' : 'Company History') :
                page4p == 2 ? (lan == '0' ? '企业荣誉' : 'Company Honors') :
                  (lan == '0' ? '合作伙伴' : 'Partners') }}
              <h4>{{ lan == '0' ?
                (page4p == 0 ? 'About Us' : page4p == 1 ? 'Company History' : page4p == 2 ? 'Corporate Honors'
                  : 'Partners') :
                '' }}</h4>
            </h3>
            <div class="page-in_content max">
              <div class="p5"></div>
              <div :class="`p6 ${lan == '0' ? '' : 'e1'}`"></div>
              <div class="p6-1  p6-pc1" :style="`${page4p == 1 ? '' : 'z-index: -99'}`" @mousewheel.stop="scmove">
                <div class="line"
                  :style="`width :${page4T.length * 10}rem;transform: scale(${page4p == 1 ? 1 : 0},1);transform-origin: left center;transition: ${page4T.length / 4}s;`">
                </div>
                <div class="line1" v-for="(    item, index    ) in     page4T    "
                  :style="`transform: translate(0, ${index % 2 == 0 ? 'calc(-100% - 1rem)' : '1rem'});left: ${10 * index + 2}rem;${index % 2 != 0 ? 'padding-top: 0;padding-bottom: 1rem;' : ''}`">
                  <p
                    :style="`${page4p == 1 ? 'opacity: 1;transition-duration: 0.2s;transition-delay: ' + (index * 0.2 + 0.25) + 's;' : ''}`">
                    {{ item.date }}</p>
                  <p
                    :style="`${page4p == 1 ? 'opacity: 1;transition-duration: 0.2s;transition-delay: ' + (index * 0.2 + 0.25) + 's;' : ''}`">
                    {{ item.content }}</p>
                  <span
                    :style="`${index % 2 == 0 ? 'top: -0.125rem;' : 'top: -0.875rem;'};${page4p == 1 ? 'transform: scale(1,1);transform-origin: left ' + (index % 2 == 0 ? 'bottom' : 'top') + ';transition-duration: 0.2s;transition-delay: ' + index * 0.2 + 's;' : ''}`"></span>
                  <span
                    :style="`${index % 2 == 0 ? 'top: -0.125rem;' : 'top: calc(100% + 0.125rem);'};${page4p == 1 ? 'transform: scale(1,1);transform-origin: left center;transition-duration: 0.2s;transition-delay: ' + (index * 0.2 + 0.25) + 's;' : ''}`"></span>
                </div>
              </div>
              <div class="p6-2  p6-pc" :style="`${page4p == 2 ? '' : 'z-index: -99'}`" @mousewheel.stop="scmove">

                <div v-for="(    v1, index    ) in     page4B    "
                  :style="`${page4p == 2 ? 'transform: translate(0,0);opacity: 1;transition-duration: 0.25s;transition-delay: ' + (index * 0.2) + 's;' : ''}`">
                  <img :src="`${baseUrl}${v1.coverPicture}`" />
                </div>
              </div>
              <div class="p6-3">
                <img src="/images/page3-4.png" />
              </div>
              <div class="p-pon">
                <div :class="`p-pon_l ${page4p == 0 ? 'act' : ''}`" @click="setPon('page4p', 0)"></div>
                <div :class="`p-pon_l ${page4p == 1 ? 'act' : ''}`" @click="setPon('page4p', 1)"></div>
                <div :class="`p-pon_l ${page4p == 2 ? 'act' : ''}`" @click="setPon('page4p', 2)"></div>
                <div :class="`p-pon_l ${page4p == 3 ? 'act' : ''}`" @click="setPon('page4p', 3)"></div>
              </div>
            </div>

            <div class="pagep l" @click="setPon('page4p', (page4p > 0 ? page4p -= 1 : 0))">
              <svg t="1704183948313" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M874.666667 480H224L514.133333 170.666667c12.8-12.8 10.666667-34.133333-2.133333-44.8s-32-10.666667-44.8 2.133333l-341.333333 362.666667c-2.133333 2.133333-4.266667 6.4-6.4 8.533333-2.133333 4.266667-2.133333 6.4-2.133334 10.666667s0 8.533333 2.133334 10.666666c2.133333 4.266667 4.266667 6.4 6.4 8.533334l341.333333 362.666666c6.4 6.4 14.933333 10.666667 23.466667 10.666667 8.533333 0 14.933333-2.133333 21.333333-8.533333 12.8-12.8 12.8-32 2.133333-44.8L224 544H874.666667c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32z"
                  fill="#ffffff" p-id="3620"></path>
              </svg>
            </div>
            <div class="pagep r" @click="setPon('page4p', (page4p <= 2 ? page4p += 1 : 3))">
              <svg t="1704184166782" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M904.533333 522.666667c2.133333-4.266667 2.133333-6.4 2.133334-10.666667s0-8.533333-2.133334-10.666667c-2.133333-4.266667-4.266667-6.4-6.4-8.533333l-341.333333-362.666667c-12.8-12.8-32-12.8-44.8-2.133333-12.8 12.8-12.8 32-2.133333 44.8l290.133333 309.333333H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h650.666667L509.866667 853.333333c-12.8 12.8-10.666667 34.133333 2.133333 44.8 6.4 6.4 14.933333 8.533333 21.333333 8.533334 8.533333 0 17.066667-4.266667 23.466667-10.666667l341.333333-362.666667c2.133333-2.133333 4.266667-6.4 6.4-10.666666z"
                  fill="#ffffff" p-id="3876"></path>
              </svg>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide @touchmove.stop>
          <div :class="`page-in ${nav == 4 ? 'act' : ''}`">
            <h3>{{ lan == '0' ? '联系合作' : 'Contact Cooperation' }}
              <h4>{{ lan == '0' ? 'Contact Cooperation' : '' }}</h4>
            </h3>
            <div class="page-in_content max" @touchmove.stop>
              <div class="p7">
                <div class="p7_l">
                  <div class="p7_l_t">
                    <img src="/images/icon1.png" />
                  </div>
                  <p class="pl1">{{ lan == '0' ? '公司地址' : 'Company address' }}</p>
                  <a style="text-decoration: none;" target="_blank" href="https://j.map.baidu.com/f7/e8SK">
                    <p class="pl2">{{ lan == '0'
                      ? '江苏省南京市浦口区紫峰路34号'
                      : 'No. 34 Zifeng Road, Pukou District, Nanjing City, Jiangsu Province' }}</p>
                  </a>
                  <div class="map" id="map" @click=""></div>
                </div>
                <div class="p7_l">
                  <div class="p7_l_t">
                    <img src="/images/icon2.png" />
                  </div>
                  <p class="pl1">{{ lan == '0' ? '联系方式' : 'Contact information' }}</p>
                  <p class="pl2">Tel：86-25-58196277</p>
                  <p class="pl1">{{ lan == '0' ? '销售人员一' : 'Salesperson 1' }}</p>
                  <p class="pl2">{{ lan == '0' ? '晏先生' : 'Mr.Yan' }} 86-15335161728</p>
                  <p class="pl1">{{ lan == '0' ? '销售人员二' : 'Salesperson 2' }}</p>
                  <p class="pl2">{{ lan == '0' ? '刘女士' : 'Ms.Liu' }} 86-15301596495</p>
                  <p class="pl1">{{ lan == '0' ? '技术服务' : 'Technical service' }}</p>
                  <p class="pl2">{{ lan == '0' ? '谈先生' : 'Mr.Tan' }} 86-15335161726</p>
                </div>
                <div class="p7_l">
                  <div class="p7_l_t">
                    <img src="/images/icon3.png" />
                  </div>
                  <p class="pl1">{{ lan == '0' ? '协澳智能' : 'ODES' }}</p>
                  <img class="contact" src="/images/cr.png" />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide @touchmove.stop>
          <div :class="`page-in ${nav == 5 ? 'act' : ''}`">
            <h3>{{ lan == '0' ? '加入我们' : 'Join Us' }}
              <h4>{{ lan == '0' ? 'Join Us' : '' }}</h4>
            </h3>
            <div class="page-in_content max">
              <div class="p8"></div>
              <div :class="`p9 ${lan == '0' ? '' : 'e1'}`">
                <div class="p9btn">
                  <a target="_blank"
                    href=" https://jobs.51job.com/all/co259152.html?timestamp__1258=Qq%2BxgD9G0%3D9bD%2FD0DodgDiwnx0gqYTID&alichlgref=http%3A%2F%2Fwww.odes.com.cn%2F">{{
                      lan
                      == '0' ? '加入我们' : 'Join Us' }}</a>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="mouse">
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M510.804267 62.389956c-149.021012 0-269.766027 120.798227-269.766027 269.801842L241.03824 691.809225c0 149.004639 120.779807 269.800819 269.766027 269.800819 149.022035 0 269.766027-120.79618 269.766027-269.800819L780.570293 332.191798C780.570293 183.188182 659.790486 62.389956 510.804267 62.389956zM726.617497 691.951465c0 119.110795-96.596012 215.70783-215.813231 215.70783-119.198799 0-215.812207-96.632851-215.812207-215.70783L294.992059 332.051605c0-119.147634 96.596012-215.70783 215.812207-215.70783 119.199823 0 215.813231 96.631828 215.813231 215.70783L726.617497 691.951465z">
          </path>
        </svg>
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M474.835054 260.21858c0-19.881816 15.965623-35.96819 35.96819-35.96819l0 0c19.863397 0 35.969213 15.947204 35.969213 35.96819l0 107.906615c0 19.846-15.965623 35.96819-35.969213 35.96819l0 0c-19.863397 0-35.96819-15.981996-35.96819-35.96819L474.835054 260.21858z">
          </path>
        </svg>
      </div>
    </div>
    <div class="sol" v-if="solAlert.open" @click="solClose">
      <div class="sol_close" @click="solClose">
        <svg t="1704171974474" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM512 832c-179.2 0-320-140.8-320-320s140.8-320 320-320 320 140.8 320 320S691.2 832 512 832z"
            p-id="2326" fill="#666"></path>
          <path
            d="M672 352c-12.8-12.8-32-12.8-44.8 0L512 467.2 396.8 352C384 339.2 364.8 339.2 352 352S339.2 384 352 396.8L467.2 512 352 627.2c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l115.2 115.2c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l115.2-115.2C684.8 384 684.8 364.8 672 352z"
            p-id="2327" fill="#666"></path>
        </svg>
      </div>
      <div class="sol_img" @click.stop>
        <div class="sol_img_t">
          <img v-if="solAlert.data.coverPicture && solAlert.data.content != ''"
            :src="`${baseUrl}${solAlert.data.content}`" />
          <p v-else>{{ lan == '0' ? '暂无内容' : 'No content' }}</p>
        </div>
        <div class="sol_pro">
          <template v-if="solAlert.data.products.length > 0">

            <div class="sol_pro_l" v-for="p in solAlert.data.products">
              <img :src="`${baseUrl}${p.coverPicture}`" @click="toPro(p)" />
              <p> {{ p.name }}</p>
            </div>
          </template>
          <template v-else>
            <span>{{ lan == '0' ? '暂无相关产品' : 'No products' }}</span>
          </template>

        </div>
      </div>
    </div>
    <a-modal v-model:open="user.open">
      <template #title>
        <div class="log-f">
          <div :class="`log-l ${user.type == 0 ? 'act' : ''}`" @click="user.type = 0">
            {{ lan == '0' ? '登录' : 'Login' }}
          </div>
          <div :class="`log-l ${user.type == 1 ? 'act' : ''}`" @click="user.type = 1">
            {{ lan == '0' ? '注册' : 'Reg' }}
          </div>
        </div>
      </template>
      <template #footer>
        <a-button key="back" @click="user.open = false;">{{ lan == '0' ? '取消' : 'Close' }}</a-button>
        <a-button key="submit" type="primary" @click="handleOk">{{ lan == '0' ? (user.type == 0 ? '登录' : '注册') :
          (user.type == 0 ? 'Login' : 'Reg') }}</a-button>
      </template>
      <a-form v-if="user.type == 0" style="marginTop: 30px" :label-col="{
        style: { width: '140px' }
      }" layout="horizontal">
        <a-form-item :label="`${lan == '0' ? '手机号' : 'Phone'}`">
          <a-input v-model:value="user.login.phone" type="text"
            :placeholder="`${lan == '0' ? '请输入手机号' : 'Please enter phone number'}`">
            <template #addonBefore>
              <a-select v-model:value="user.login.a" style="width: 150px">
                <a-select-option :value="item.code" v-for="item in phona">
                  {{ lan == '0' ? item.Name : item.EnName }}({{ item.code }})
                </a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '密码' : 'Password'}`">
          <a-input type="password" v-model:value="user.login.password"
            :placeholder="`${lan == '0' ? '请输入密码' : 'Please enter the password'}`" />
        </a-form-item>

      </a-form>
      <a-form style="marginTop: 30px" :label-col="{
        style: { width: '140px' }
      }" layout="horizontal" v-else>
        <a-form-item :label="`${lan == '0' ? '手机号' : 'Phone'}`">
          <a-input v-model:value="user.reg.phone" type="text"
            :placeholder="`${lan == '0' ? '请输入手机号' : 'Please enter phone number'}`">
            <template #addonBefore>
              <a-select v-model:value="user.reg.a" style="width: 150px">
                <a-select-option :value="item.code" v-for="item in phona">
                  {{ lan == '0' ? item.Name : item.EnName }}({{ item.code }})
                </a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '验证码' : 'Code'}`">
          <a-input-group compact>
            <a-input style="width: calc(100% - 130px)" v-model:value="user.reg.code"
              :placeholder="`${lan == '0' ? '请输入验证码' : 'Please enter the code'}`" />
            <a-button style="width: 130px" type="primary" @click="getCode">{{ user.reg.time == 60 ? (lan == '0' ? '发送验证码'
              : 'Send code') : user.reg.time }}</a-button>
          </a-input-group>
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '密码' : 'Password'}`">
          <a-input type="password" v-model:value="user.reg.password"
            :placeholder="`${lan == '0' ? '请输入密码' : 'Please enter the password'}`" />
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '姓名' : 'Name'}`">
          <a-input :placeholder="`${lan == '0' ? '请输入姓名' : 'Please enter the name'}`" v-model:value="user.reg.name" />
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '公司' : 'Company'}`">
          <a-input :placeholder="`${lan == '0' ? '请输入公司' : 'Please enter the company'}`"
            v-model:value="user.reg.company" />
        </a-form-item>
        <a-form-item :label="`${lan == '0' ? '职位' : 'Job'}`">
          <a-input :placeholder="`${lan == '0' ? '请输入职位' : 'Please enter the job'}`" v-model:value="user.reg.job" />
        </a-form-item>
      </a-form>
      <p style="text-align: center;color :red;font-size: 12px;">
        {{ lan == '0' ?
          '如账号无法登录或忘记密码请联系我们，联系电话：025-58196277' :
          'If you cannot log in to your account or forget your password,please contact us,EMAIL：shangwu1@odes.com.cn' }}</p>
    </a-modal>
    <div class="search-m" v-if="search.open"
      @mousedown="search.open = false, search.data1 = [], search.data2 = [], search.v = ''">
      <div class="search-m_g" @mousedown.stop="">
        <div class="search-m_l">
          <a-input-search allow-clear v-model:value="search.v"
            :placeholder="`${lan == '0' ? '输入关键词' : 'Enter a keyword'}`" :enter-button="`${lan == '0' ? '搜索' : 'Search'}`"
            @search="onSearch" @click.stop />
        </div>
        <div class="search-m_b">
          <div v-for="i in search.data1" @click.stop="goSearch(i.productId, 1)">
            {{ i.name }}
          </div>
          <div v-for="i in search.data2" @click.stop="goSearch(i.solutionId, 2)">
            {{ i.name }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, watchEffect, computed, getCurrentInstance, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Mousewheel } from 'swiper/modules';
import quest from '../api/index';
import CryptoJS from 'crypto-js';
import api from '../api/url';
import { useRoute } from 'vue-router';

import { message } from 'ant-design-vue';
let KEY = "12345678900000001234567890000000";
let IV = "1234567890000000";
export default {
  name: 'Index',
  props: {
    msg: String
  },
  components: {
    Swiper,
  },
  setup() {
    let menu = ref(false);
    const nav = ref(0);
    const route = useRoute();
    const query = computed(() => route.query.page);
    const instance = getCurrentInstance();
    let user = ref({
      open: false,
      type: 0,
      login: ref({
        a: '+86',
        password: '',
        phone: '',
      }),
      reg: ref({
        a: '+86',
        password: '',
        phone: '',
        name: '',
        code: '',
        job: '',
        company: '',
        time: 60,
        st: null,
      })
    })
    let solArr = ref([]);
    let prodArr = ref([]);
    let prodDeArr = ref([]);
    let prodIndex = ref(0);
    let prodAll = ref(0);
    let hover = ref(0);
    let deQuery = '';
    let page1p = ref(0);
    let page4p = ref(0);
    let lan = ref(window.localStorage.getItem('lan') ? window.localStorage.getItem('lan') : 0);
    let page4T = ref([]);
    let page4B = ref([]);
    let chosePro = ref({
      show: false,
      fa: ref([]),
      data: ref(undefined),
      index: 0
    })
    let search = ref({
      open: false,
      data1: ref([]),
      data2: ref([]),
      v: ''
    });


    watchEffect(() => {
      if (query.value) {
        if (deQuery == '') {
          deQuery = query.value;
        } else {
          return;
        }
        setTimeout(() => {
          if (instance.proxy.setNav) {
            instance.proxy.setNav(query.value == 'solution' ? 1 : query.value == 'product' ? 2 : query.value == 'about' ? 3 : query.value == 'contact' ? 4 : 5)
          }
        }, 500);
      }
    })
    let solAlert = ref({
      open: false,
      data: ref(undefined)
    })
    let timer = null;
    let st = 0;
    let baseUrl = ref(api.api);
    let pages = ref(0);
    let phona = [
      {
        "EnName": "China",
        "Name": "中国",
        "code": "+86"
      },
      {
        "EnName": "Afghanistan",
        "Name": "阿富汗",
        "IOS2": "AF",
        "IOS3": "AFG",
        "code": "+93"
      },
      {
        "EnName": "Albania",
        "Name": "阿尔巴尼亚",
        "IOS2": "AL",
        "IOS3": "ALB",
        "code": "+355"
      },
      {
        "EnName": "Algeria",
        "Name": "阿尔及利亚",
        "IOS2": "DZ",
        "IOS3": "DZA",
        "code": "+213"
      },
      {
        "EnName": "American Samoa",
        "Name": "美属萨摩亚",
        "IOS2": "AS",
        "IOS3": "ASM",
        "code": "+1684"
      },
      {
        "EnName": "Andorra",
        "Name": "安道尔",
        "IOS2": "AD",
        "IOS3": "AND",
        "code": "+376"
      },
      {
        "EnName": "Angola",
        "Name": "安哥拉",
        "IOS2": "AO",
        "IOS3": "AGO",
        "code": "+244"
      },
      {
        "EnName": "Anguilla",
        "Name": "安圭拉",
        "IOS2": "AI",
        "IOS3": "AIA",
        "code": "+1264"
      },
      {
        "EnName": "Antigua and Barbuda",
        "Name": "安提瓜和巴布达",
        "IOS2": "AG",
        "IOS3": "ATG",
        "code": "+1268"
      },
      {
        "EnName": "Argentina",
        "Name": "阿根廷",
        "IOS2": "AR",
        "IOS3": "ARG",
        "code": "+54"
      },
      {
        "EnName": "Armenia",
        "Name": "亚美尼亚",
        "IOS2": "AM",
        "IOS3": "ARM",
        "code": "+374"
      },
      {
        "EnName": "Aruba",
        "Name": "阿鲁巴",
        "IOS2": "AW",
        "IOS3": "ABW",
        "code": "+297"
      },
      {
        "EnName": "Australia",
        "Name": "澳大利亚",
        "IOS2": "AU",
        "IOS3": "AUS",
        "code": "+61"
      },
      {
        "EnName": "Austria",
        "Name": "奥地利",
        "IOS2": "AT",
        "IOS3": "AUT",
        "code": "+43"
      },
      {
        "EnName": "Azerbaijan",
        "Name": "阿塞拜疆",
        "IOS2": "AZ",
        "IOS3": "AZE",
        "code": "+994"
      },
      {
        "EnName": "Bahamas",
        "Name": "巴哈马",
        "IOS2": "BS",
        "IOS3": "BHS",
        "code": "+1242"
      },
      {
        "EnName": "Bahrain",
        "Name": "巴林",
        "IOS2": "BH",
        "IOS3": "BHR",
        "code": "+973"
      },
      {
        "EnName": "Bangladesh",
        "Name": "孟加拉国",
        "IOS2": "BD",
        "IOS3": "BGD",
        "code": "+880"
      },
      {
        "EnName": "Barbados",
        "Name": "巴巴多斯",
        "IOS2": "BB",
        "IOS3": "BRB",
        "code": "+1246"
      },
      {
        "EnName": "Belarus",
        "Name": "白俄罗斯",
        "IOS2": "BY",
        "IOS3": "BLR",
        "code": "+375"
      },
      {
        "EnName": "Belgium",
        "Name": "比利时",
        "IOS2": "BE",
        "IOS3": "BEL",
        "code": "+32"
      },
      {
        "EnName": "Belize",
        "Name": "伯利兹",
        "IOS2": "BZ",
        "IOS3": "BLZ",
        "code": "+501"
      },
      {
        "EnName": "Benin",
        "Name": "贝宁",
        "IOS2": "BJ",
        "IOS3": "BEN",
        "code": "+229"
      },
      {
        "EnName": "Bermuda",
        "Name": "百慕大",
        "IOS2": "BM",
        "IOS3": "BMU",
        "code": "+1441"
      },
      {
        "EnName": "Bhutan",
        "Name": "不丹",
        "IOS2": "BT",
        "IOS3": "BTN",
        "code": "+975"
      },
      {
        "EnName": "Bolivia",
        "Name": "玻利维亚",
        "IOS2": "BO",
        "IOS3": "BOL",
        "code": "+591"
      },
      {
        "EnName": "Bosnia and Herzegovina",
        "Name": "波斯尼亚和黑塞哥维那",
        "IOS2": "BA",
        "IOS3": "BIH",
        "code": "+387"
      },
      {
        "EnName": "Botswana",
        "Name": "博茨瓦纳",
        "IOS2": "BW",
        "IOS3": "BWA",
        "code": "+267"
      },
      {
        "EnName": "Brazil",
        "Name": "巴西",
        "IOS2": "BR",
        "IOS3": "BRA",
        "code": "+55"
      },
      {
        "EnName": "British Indian Ocean Territory",
        "Name": "英属印度洋领地",
        "IOS2": "IO",
        "IOS3": "IOT",
        "code": "+246"
      },
      {
        "EnName": "British Virgin Islands",
        "Name": "英属维尔京群岛",
        "IOS2": "VG",
        "IOS3": "VGB",
        "code": "+1284"
      },
      {
        "EnName": "Brunei",
        "Name": "文莱",
        "IOS2": "BN",
        "IOS3": "BRN",
        "code": "+673"
      },
      {
        "EnName": "Bulgaria",
        "Name": "保加利亚",
        "IOS2": "BG",
        "IOS3": "BGR",
        "code": "+359"
      },
      {
        "EnName": "Burkina Faso",
        "Name": "布基纳法索",
        "IOS2": "BF",
        "IOS3": "BFA",
        "code": "+226"
      },
      {
        "EnName": "Burundi",
        "Name": "布隆迪",
        "IOS2": "BI",
        "IOS3": "BDI",
        "code": "+257"
      },
      {
        "EnName": "Cambodia",
        "Name": "柬埔寨",
        "IOS2": "KH",
        "IOS3": "KHM",
        "code": "+855"
      },
      {
        "EnName": "Cameroon",
        "Name": "喀麦隆",
        "IOS2": "CM",
        "IOS3": "CMR",
        "code": "+237"
      },
      {
        "EnName": "Canada",
        "Name": "加拿大",
        "IOS2": "CA",
        "IOS3": "CAN",
        "code": "+1"
      },
      {
        "EnName": "Cape Verde",
        "Name": "佛得角",
        "IOS2": "CV",
        "IOS3": "CPV",
        "code": "+238"
      },
      {
        "EnName": "Cayman Islands",
        "Name": "开曼群岛",
        "IOS2": "KY",
        "IOS3": "CYM",
        "code": "+1345"
      },
      {
        "EnName": "Central African Republic",
        "Name": "中非共和国",
        "IOS2": "CF",
        "IOS3": "CAF",
        "code": "+236"
      },
      {
        "EnName": "Chad",
        "Name": "乍得",
        "IOS2": "TD",
        "IOS3": "TCD",
        "code": "+235"
      },
      {
        "EnName": "Chile",
        "Name": "智利",
        "IOS2": "CL",
        "IOS3": "CHL",
        "code": "+56"
      },
      // {
      //   "EnName": "Christmas Island",
      //   "Name": "圣诞岛",
      //   "IOS2": "CX",
      //   "IOS3": "CXR",
      //   "code": "+61"
      // },
      // {
      //   "EnName": "Cocos Islands",
      //   "Name": "科科斯群岛",
      //   "IOS2": "CC",
      //   "IOS3": "CCK",
      //   "code": "+61"
      // },
      {
        "EnName": "Colombia",
        "Name": "哥伦比亚",
        "IOS2": "CO",
        "IOS3": "COL",
        "code": "+57"
      },
      {
        "EnName": "Comoros",
        "Name": "科摩罗",
        "IOS2": "KM",
        "IOS3": "COM",
        "code": "+269"
      },
      {
        "EnName": "Cook Islands",
        "Name": "库克群岛",
        "IOS2": "CK",
        "IOS3": "COK",
        "code": "+682"
      },
      {
        "EnName": "Costa Rica",
        "Name": "哥斯达黎加",
        "IOS2": "CR",
        "IOS3": "CRI",
        "code": "+506"
      },
      {
        "EnName": "Croatia",
        "Name": "克罗地亚",
        "IOS2": "HR",
        "IOS3": "HRV",
        "code": "+385"
      },
      {
        "EnName": "Cyprus",
        "Name": "塞浦路斯",
        "IOS2": "CY",
        "IOS3": "CYP",
        "code": "+357"
      },
      {
        "EnName": "Czech Republic",
        "Name": "捷克共和国",
        "IOS2": "CZ",
        "IOS3": "CZE",
        "code": "+420"
      },
      {
        "EnName": "Democratic Republic of the Congo",
        "Name": "刚果民主共和国",
        "IOS2": "CD",
        "IOS3": "COD",
        "code": "+243"
      },
      {
        "EnName": "Denmark",
        "Name": "丹麦",
        "IOS2": "DK",
        "IOS3": "DNK",
        "code": "+45"
      },
      {
        "EnName": "Djibouti",
        "Name": "吉布提",
        "IOS2": "DJ",
        "IOS3": "DJI",
        "code": "+253"
      },
      {
        "EnName": "Dominica",
        "Name": "多米尼加",
        "IOS2": "DM",
        "IOS3": "DMA",
        "code": "+1767"
      },
      {
        "EnName": "Dominican Republic",
        "Name": "多明尼加共和国",
        "IOS2": "DO",
        "IOS3": "DOM",
        "code": "+1809"
      },
      {
        "EnName": "East Timor",
        "Name": "东帝汶",
        "IOS2": "TL",
        "IOS3": "TLS",
        "code": "+670"
      },
      {
        "EnName": "Ecuador",
        "Name": "厄瓜多尔",
        "IOS2": "EC",
        "IOS3": "ECU",
        "code": "+593"
      },
      {
        "EnName": "Egypt",
        "Name": "埃及",
        "IOS2": "EG",
        "IOS3": "EGY",
        "code": "+20"
      },
      {
        "EnName": "El Salvador",
        "Name": "萨尔瓦多",
        "IOS2": "SV",
        "IOS3": "SLV",
        "code": "+503"
      },
      {
        "EnName": "Equatorial Guinea",
        "Name": "赤道几内亚",
        "IOS2": "GQ",
        "IOS3": "GNQ",
        "code": "+240"
      },
      {
        "EnName": "Eritrea",
        "Name": "厄立特里亚",
        "IOS2": "ER",
        "IOS3": "ERI",
        "code": "+291"
      },
      {
        "EnName": "Estonia",
        "Name": "爱沙尼亚",
        "IOS2": "EE",
        "IOS3": "EST",
        "code": "+372"
      },
      {
        "EnName": "Ethiopia",
        "Name": "埃塞俄比亚",
        "IOS2": "ET",
        "IOS3": "ETH",
        "code": "+251"
      },
      {
        "EnName": "Falkland Islands",
        "Name": "福克兰群岛",
        "IOS2": "FK",
        "IOS3": "FLK",
        "code": "+500"
      },
      {
        "EnName": "Faroe Islands",
        "Name": "法罗群岛",
        "IOS2": "FO",
        "IOS3": "FRO",
        "code": "+298"
      },
      {
        "EnName": "Fiji",
        "Name": "斐济",
        "IOS2": "FJ",
        "IOS3": "FJI",
        "code": "+679"
      },
      {
        "EnName": "Finland",
        "Name": "芬兰",
        "IOS2": "FI",
        "IOS3": "FIN",
        "code": "+358"
      },
      {
        "EnName": "France",
        "Name": "法国",
        "IOS2": "FR",
        "IOS3": "FRA",
        "code": "+33"
      },
      {
        "EnName": "French Polynesia",
        "Name": "法属波利尼西亚",
        "IOS2": "PF",
        "IOS3": "PYF",
        "code": "+689"
      },
      {
        "EnName": "Gabon",
        "Name": "加蓬",
        "IOS2": "GA",
        "IOS3": "GAB",
        "code": "+241"
      },
      {
        "EnName": "Gambia",
        "Name": "冈比亚",
        "IOS2": "GM",
        "IOS3": "GMB",
        "code": "+220"
      },
      {
        "EnName": "Georgia",
        "Name": "格鲁吉亚",
        "IOS2": "GE",
        "IOS3": "GEO",
        "code": "+995"
      },
      {
        "EnName": "Germany",
        "Name": "德国",
        "IOS2": "DE",
        "IOS3": "DEU",
        "code": "+49"
      },
      {
        "EnName": "Ghana",
        "Name": "加纳",
        "IOS2": "GH",
        "IOS3": "GHA",
        "code": "+233"
      },
      {
        "EnName": "Gibraltar",
        "Name": "直布罗陀",
        "IOS2": "GI",
        "IOS3": "GIB",
        "code": "+350"
      },
      {
        "EnName": "Greece",
        "Name": "希腊",
        "IOS2": "GR",
        "IOS3": "GRC",
        "code": "+30"
      },
      {
        "EnName": "Greenland",
        "Name": "格陵兰",
        "IOS2": "GL",
        "IOS3": "GRL",
        "code": "+299"
      },
      {
        "EnName": "Grenada",
        "Name": "格林纳达",
        "IOS2": "GD",
        "IOS3": "GRD",
        "code": "+1473"
      },
      {
        "EnName": "Guam",
        "Name": "关岛",
        "IOS2": "GU",
        "IOS3": "GUM",
        "code": "+1671"
      },
      {
        "EnName": "Guatemala",
        "Name": "危地马拉",
        "IOS2": "GT",
        "IOS3": "GTM",
        "code": "+502"
      },
      {
        "EnName": "Guinea",
        "Name": "几内亚",
        "IOS2": "GN",
        "IOS3": "GIN",
        "code": "+224"
      },
      {
        "EnName": "Guyana",
        "Name": "圭亚那",
        "IOS2": "GY",
        "IOS3": "GUY",
        "code": "+592"
      },
      {
        "EnName": "Haiti",
        "Name": "海地",
        "IOS2": "HT",
        "IOS3": "HTI",
        "code": "+509"
      },
      {
        "EnName": "Honduras",
        "Name": "洪都拉斯",
        "IOS2": "HN",
        "IOS3": "HND",
        "code": "+504"
      },
      {
        "EnName": "Hong Kong, China",
        "Name": "中国香港",
        "IOS2": "HK",
        "IOS3": "HKG",
        "code": "+852"
      },
      {
        "EnName": "Hungary",
        "Name": "匈牙利",
        "IOS2": "HU",
        "IOS3": "HUN",
        "code": "+36"
      },
      {
        "EnName": "Iceland",
        "Name": "冰岛",
        "IOS2": "IS",
        "IOS3": "ISL",
        "code": "+354"
      },
      {
        "EnName": "India",
        "Name": "印度",
        "IOS2": "IN",
        "IOS3": "IND",
        "code": "+91"
      },
      {
        "EnName": "Indonesia",
        "Name": "印度尼西亚",
        "IOS2": "ID",
        "IOS3": "IDN",
        "code": "+62"
      },
      {
        "EnName": "Iraq",
        "Name": "伊拉克",
        "IOS2": "IQ",
        "IOS3": "IRQ",
        "code": "+964"
      },
      {
        "EnName": "Ireland",
        "Name": "爱尔兰",
        "IOS2": "IE",
        "IOS3": "IRL",
        "code": "+353"
      },
      {
        "EnName": "Israel",
        "Name": "以色列",
        "IOS2": "IL",
        "IOS3": "ISR",
        "code": "+972"
      },
      {
        "EnName": "Italy",
        "Name": "意大利",
        "IOS2": "IT",
        "IOS3": "ITA",
        "code": "+39"
      },
      {
        "EnName": "Jamaica",
        "Name": "牙买加",
        "IOS2": "JM",
        "IOS3": "JAM",
        "code": "+1876"
      },
      {
        "EnName": "Japan",
        "Name": "日本",
        "IOS2": "JP",
        "IOS3": "JPN",
        "code": "+81"
      },
      {
        "EnName": "Jordan",
        "Name": "约旦",
        "IOS2": "JO",
        "IOS3": "JOR",
        "code": "+962"
      },
      {
        "EnName": "Kazakhstan",
        "Name": "哈萨克斯坦",
        "IOS2": "KZ",
        "IOS3": "KAZ",
        "code": "+7"
      },
      {
        "EnName": "Kenya",
        "Name": "肯尼亚",
        "IOS2": "KE",
        "IOS3": "KEN",
        "code": "+254"
      },
      {
        "EnName": "Kiribati",
        "Name": "基里巴斯",
        "IOS2": "KI",
        "IOS3": "KIR",
        "code": "+686"
      },
      {
        "EnName": "Kuwait",
        "Name": "科威特",
        "IOS2": "KW",
        "IOS3": "KWT",
        "code": "+965"
      },
      {
        "EnName": "Kyrgyzstan",
        "Name": "吉尔吉斯斯坦",
        "IOS2": "KG",
        "IOS3": "KGZ",
        "code": "+996"
      },
      {
        "EnName": "Laos",
        "Name": "老挝",
        "IOS2": "LA",
        "IOS3": "LAO",
        "code": "+856"
      },
      {
        "EnName": "Latvia",
        "Name": "拉脱维亚",
        "IOS2": "LV",
        "IOS3": "LVA",
        "code": "+371"
      },
      {
        "EnName": "Lebanon",
        "Name": "黎巴嫩",
        "IOS2": "LB",
        "IOS3": "LBN",
        "code": "+961"
      },
      {
        "EnName": "Lesotho",
        "Name": "莱索托",
        "IOS2": "LS",
        "IOS3": "LSO",
        "code": "+266"
      },
      {
        "EnName": "Liberia",
        "Name": "利比里亚",
        "IOS2": "LR",
        "IOS3": "LBR",
        "code": "+231"
      },
      {
        "EnName": "Libya",
        "Name": "利比亚",
        "IOS2": "LY",
        "IOS3": "LBY",
        "code": "+218"
      },
      {
        "EnName": "Liechtenstein",
        "Name": "列支敦士登",
        "IOS2": "LI",
        "IOS3": "LIE",
        "code": "+423"
      },
      {
        "EnName": "Lithuania",
        "Name": "立陶宛",
        "IOS2": "LT",
        "IOS3": "LTU",
        "code": "+370"
      },
      {
        "EnName": "Luxembourg",
        "Name": "卢森堡",
        "IOS2": "LU",
        "IOS3": "LUX",
        "code": "+352"
      },
      {
        "EnName": "Macau, China",
        "Name": "中国澳门",
        "IOS2": "MO",
        "IOS3": "MAC",
        "code": "+853"
      },
      {
        "EnName": "Macedonia",
        "Name": "马其顿",
        "IOS2": "MK",
        "IOS3": "MKD",
        "code": "+389"
      },
      {
        "EnName": "Madagascar",
        "Name": "马达加斯加",
        "IOS2": "MG",
        "IOS3": "MDG",
        "code": "+261"
      },
      {
        "EnName": "Malawi",
        "Name": "马拉维",
        "IOS2": "MW",
        "IOS3": "MWI",
        "code": "+265"
      },
      {
        "EnName": "Malaysia",
        "Name": "马来西亚",
        "IOS2": "MY",
        "IOS3": "MYS",
        "code": "+60"
      },
      {
        "EnName": "Maldives",
        "Name": "马尔代夫",
        "IOS2": "MV",
        "IOS3": "MDV",
        "code": "+960"
      },
      {
        "EnName": "Mali",
        "Name": "马里",
        "IOS2": "ML",
        "IOS3": "MLI",
        "code": "+223"
      },
      {
        "EnName": "Malta",
        "Name": "马耳他",
        "IOS2": "MT",
        "IOS3": "MLT",
        "code": "+356"
      },
      {
        "EnName": "Marshall Islands",
        "Name": "马绍尔群岛",
        "IOS2": "MH",
        "IOS3": "MHL",
        "code": "+692"
      },
      {
        "EnName": "Mauritania",
        "Name": "毛里塔尼亚",
        "IOS2": "MR",
        "IOS3": "MRT",
        "code": "+222"
      },
      {
        "EnName": "Mauritius",
        "Name": "毛里求斯",
        "IOS2": "MU",
        "IOS3": "MUS",
        "code": "+230"
      },
      {
        "EnName": "Mayotte",
        "Name": "马约特",
        "IOS2": "YT",
        "IOS3": "MYT",
        "code": "+262"
      },
      {
        "EnName": "Mexico",
        "Name": "墨西哥",
        "IOS2": "MX",
        "IOS3": "MEX",
        "code": "+52"
      },
      {
        "EnName": "Micronesia",
        "Name": "密克罗尼西亚",
        "IOS2": "FM",
        "IOS3": "FSM",
        "code": "+691"
      },
      {
        "EnName": "Moldova",
        "Name": "摩尔多瓦",
        "IOS2": "MD",
        "IOS3": "MDA",
        "code": "+373"
      },
      {
        "EnName": "Monaco",
        "Name": "摩纳哥",
        "IOS2": "MC",
        "IOS3": "MCO",
        "code": "+377"
      },
      {
        "EnName": "Mongolia",
        "Name": "蒙古",
        "IOS2": "MN",
        "IOS3": "MNG",
        "code": "+976"
      },
      {
        "EnName": "Montenegro",
        "Name": "黑山",
        "IOS2": "ME",
        "IOS3": "MNE",
        "code": "+382"
      },
      {
        "EnName": "Montserrat",
        "Name": "蒙特塞拉特",
        "IOS2": "MS",
        "IOS3": "MSR",
        "code": "+1664"
      },
      {
        "EnName": "Morocco",
        "Name": "摩洛哥",
        "IOS2": "MA",
        "IOS3": "MAR",
        "code": "+212"
      },
      {
        "EnName": "Mozambique",
        "Name": "莫桑比克",
        "IOS2": "MZ",
        "IOS3": "MOZ",
        "code": "+258"
      },
      {
        "EnName": "Myanmar",
        "Name": "缅甸",
        "IOS2": "MM",
        "IOS3": "MMR",
        "code": "+95"
      },
      {
        "EnName": "Namibia",
        "Name": "纳米比亚",
        "IOS2": "NA",
        "IOS3": "NAM",
        "code": "+264"
      },
      {
        "EnName": "Nauru",
        "Name": "瑙鲁",
        "IOS2": "NR",
        "IOS3": "NRU",
        "code": "+674"
      },
      {
        "EnName": "Nepal",
        "Name": "尼泊尔",
        "IOS2": "NP",
        "IOS3": "NPL",
        "code": "+977"
      },
      {
        "EnName": "Netherlands",
        "Name": "荷兰",
        "IOS2": "NL",
        "IOS3": "NLD",
        "code": "+31"
      },
      {
        "EnName": "Netherlands Antilles",
        "Name": "荷属安的列斯",
        "IOS2": "AN",
        "IOS3": "ANT",
        "code": "+599"
      },
      {
        "EnName": "New Caledonia",
        "Name": "新喀里多尼亚",
        "IOS2": "NC",
        "IOS3": "NCL",
        "code": "+687"
      },
      {
        "EnName": "New Zealand",
        "Name": "新西兰",
        "IOS2": "NZ",
        "IOS3": "NZL",
        "code": "+64"
      },
      {
        "EnName": "Nicaragua",
        "Name": "尼加拉瓜",
        "IOS2": "NI",
        "IOS3": "NIC",
        "code": "+505"
      },
      {
        "EnName": "Niger",
        "Name": "尼日尔",
        "IOS2": "NE",
        "IOS3": "NER",
        "code": "+227"
      },
      {
        "EnName": "Nigeria",
        "Name": "尼日利亚",
        "IOS2": "NG",
        "IOS3": "NGA",
        "code": "+234"
      },
      {
        "EnName": "Niue",
        "Name": "纽埃",
        "IOS2": "NU",
        "IOS3": "NIU",
        "code": "+683"
      },
      {
        "EnName": "Northern Mariana Islands",
        "Name": "北马里亚纳群岛",
        "IOS2": "MP",
        "IOS3": "MNP",
        "code": "+1670"
      },
      {
        "EnName": "Norway",
        "Name": "挪威",
        "IOS2": "NO",
        "IOS3": "NOR",
        "code": "+47"
      },
      {
        "EnName": "Oman",
        "Name": "阿曼",
        "IOS2": "OM",
        "IOS3": "OMN",
        "code": "+968"
      },
      {
        "EnName": "Pakistan",
        "Name": "巴基斯坦",
        "IOS2": "PK",
        "IOS3": "PAK",
        "code": "+92"
      },
      {
        "EnName": "Palau",
        "Name": "帕劳",
        "IOS2": "PW",
        "IOS3": "PLW",
        "code": "+680"
      },
      {
        "EnName": "Palestine",
        "Name": "巴勒斯坦",
        "IOS2": "PS",
        "IOS3": "PSE",
        "code": "+970"
      },
      {
        "EnName": "Panama",
        "Name": "巴拿马",
        "IOS2": "PA",
        "IOS3": "PAN",
        "code": "+507"
      },
      {
        "EnName": "Papua New Guinea",
        "Name": "巴布亚新几内亚",
        "IOS2": "PG",
        "IOS3": "PNG",
        "code": "+675"
      },
      {
        "EnName": "Paraguay",
        "Name": "巴拉圭",
        "IOS2": "PY",
        "IOS3": "PRY",
        "code": "+595"
      },
      {
        "EnName": "Peru",
        "Name": "秘鲁",
        "IOS2": "PE",
        "IOS3": "PER",
        "code": "+51"
      },
      {
        "EnName": "Philippines",
        "Name": "菲律宾",
        "IOS2": "PH",
        "IOS3": "PHL",
        "code": "+63"
      },
      {
        "EnName": "Poland",
        "Name": "波兰",
        "IOS2": "PL",
        "IOS3": "POL",
        "code": "+48"
      },
      {
        "EnName": "Portugal",
        "Name": "葡萄牙",
        "IOS2": "PT",
        "IOS3": "PRT",
        "code": "+351"
      },
      {
        "EnName": "Puerto Rico",
        "Name": "波多黎各",
        "IOS2": "PR",
        "IOS3": "PRI",
        "code": "+1787"
      },
      {
        "EnName": "Qatar",
        "Name": "卡塔尔",
        "IOS2": "QA",
        "IOS3": "QAT",
        "code": "+974"
      },
      {
        "EnName": "Republic of the Congo",
        "Name": "刚果民主共和国",
        "IOS2": "CG",
        "IOS3": "COG",
        "code": "+242"
      },
      {
        "EnName": "Reunion",
        "Name": "留尼汪",
        "IOS2": "RE",
        "IOS3": "REU",
        "code": "+262"
      },
      {
        "EnName": "Romania",
        "Name": "罗马尼亚",
        "IOS2": "RO",
        "IOS3": "ROU",
        "code": "+40"
      },
      {
        "EnName": "Rwanda",
        "Name": "卢旺达",
        "IOS2": "RW",
        "IOS3": "RWA",
        "code": "+250"
      },
      {
        "EnName": "Saint Helena",
        "Name": "圣赫勒拿",
        "IOS2": "SH",
        "IOS3": "SHN",
        "code": "+290"
      },
      {
        "EnName": "Saint Kitts and Nevis",
        "Name": "圣基茨和尼维斯",
        "IOS2": "KN",
        "IOS3": "KNA",
        "code": "+1869"
      },
      {
        "EnName": "Saint Lucia",
        "Name": "圣卢西亚",
        "IOS2": "LC",
        "IOS3": "LCA",
        "code": "+1758"
      },
      {
        "EnName": "Saint Pierre and Miquelon",
        "Name": "圣皮埃尔和密克隆",
        "IOS2": "PM",
        "IOS3": "SPM",
        "code": "+508"
      },
      {
        "EnName": "Saint Vincent and the Grenadines",
        "Name": "圣文森特和格林纳丁斯",
        "IOS2": "VC",
        "IOS3": "VCT",
        "code": "+1784"
      },
      {
        "EnName": "Samoa",
        "Name": "萨摩亚",
        "IOS2": "WS",
        "IOS3": "WSM",
        "code": "+685"
      },
      {
        "EnName": "San Marino",
        "Name": "圣马力诺",
        "IOS2": "SM",
        "IOS3": "SMR",
        "code": "+378"
      },
      {
        "EnName": "Sao Tome and Principe",
        "Name": "圣多美和普林西比",
        "IOS2": "ST",
        "IOS3": "STP",
        "code": "+239"
      },
      {
        "EnName": "Saudi Arabia",
        "Name": "沙特阿拉伯",
        "IOS2": "SA",
        "IOS3": "SAU",
        "code": "+966"
      },
      {
        "EnName": "Senegal",
        "Name": "塞内加尔",
        "IOS2": "SN",
        "IOS3": "SEN",
        "code": "+221"
      },
      {
        "EnName": "Serbia",
        "Name": "塞尔维亚",
        "IOS2": "RS",
        "IOS3": "SRB",
        "code": "+381"
      },
      {
        "EnName": "Seychelles",
        "Name": "塞舌尔",
        "IOS2": "SC",
        "IOS3": "SYC",
        "code": "+248"
      },
      {
        "EnName": "Sierra Leone",
        "Name": "塞拉利昂",
        "IOS2": "SL",
        "IOS3": "SLE",
        "code": "+232"
      },
      {
        "EnName": "Singapore",
        "Name": "新加坡",
        "IOS2": "SG",
        "IOS3": "SGP",
        "code": "+65"
      },
      {
        "EnName": "Slovakia",
        "Name": "斯洛伐克",
        "IOS2": "SK",
        "IOS3": "SVK",
        "code": "+421"
      },
      {
        "EnName": "Slovenia",
        "Name": "斯洛文尼亚",
        "IOS2": "SI",
        "IOS3": "SVN",
        "code": "+386"
      },
      {
        "EnName": "Solomon Islands",
        "Name": "所罗门群岛",
        "IOS2": "SB",
        "IOS3": "SLB",
        "code": "+677"
      },
      {
        "EnName": "Somalia",
        "Name": "索马里",
        "IOS2": "SO",
        "IOS3": "SOM",
        "code": "+252"
      },
      {
        "EnName": "South Africa",
        "Name": "南非",
        "IOS2": "ZA",
        "IOS3": "ZAF",
        "code": "+27"
      },
      {
        "EnName": "South Korea",
        "Name": "韩国",
        "IOS2": "KR",
        "IOS3": "KOR",
        "code": "+82"
      },
      {
        "EnName": "South Sudan",
        "Name": "南苏丹",
        "IOS2": "SS",
        "IOS3": "SSD",
        "code": "+211"
      },
      {
        "EnName": "Spain",
        "Name": "西班牙",
        "IOS2": "ES",
        "IOS3": "ESP",
        "code": "+34"
      },
      {
        "EnName": "Sri Lanka",
        "Name": "斯里兰卡",
        "IOS2": "LK",
        "IOS3": "LKA",
        "code": "+94"
      },
      {
        "EnName": "Sudan",
        "Name": "苏丹",
        "IOS2": "SD",
        "IOS3": "SDN",
        "code": "+249"
      },
      {
        "EnName": "Suriname",
        "Name": "苏里南",
        "IOS2": "SR",
        "IOS3": "SUR",
        "code": "+597"
      },
      {
        "EnName": "Svalbard and Jan Mayen",
        "Name": "斯瓦尔巴特",
        "IOS2": "SJ",
        "IOS3": "SJM",
        "code": "+47"
      },
      {
        "EnName": "Swaziland",
        "Name": "斯威士兰",
        "IOS2": "SZ",
        "IOS3": "SWZ",
        "code": "+268"
      },
      {
        "EnName": "Sweden",
        "Name": "瑞典",
        "IOS2": "SE",
        "IOS3": "SWE",
        "code": "+46"
      },
      {
        "EnName": "Switzerland",
        "Name": "瑞士",
        "IOS2": "CH",
        "IOS3": "CHE",
        "code": "+41"
      },
      {
        "EnName": "Taiwan, China",
        "Name": "中国台湾",
        "IOS2": "TW",
        "IOS3": "TWN",
        "code": "+886"
      },
      {
        "EnName": "Tajikistan",
        "Name": "塔吉克斯坦",
        "IOS2": "TJ",
        "IOS3": "TJK",
        "code": "+992"
      },
      {
        "EnName": "Tanzania",
        "Name": "坦桑尼亚",
        "IOS2": "TZ",
        "IOS3": "TZA",
        "code": "+255"
      },
      {
        "EnName": "Thailand",
        "Name": "泰国",
        "IOS2": "TH",
        "IOS3": "THA",
        "code": "+66"
      },
      {
        "EnName": "Togo",
        "Name": "多哥",
        "IOS2": "TG",
        "IOS3": "TGO",
        "code": "+228"
      },
      {
        "EnName": "Tokelau",
        "Name": "托克劳",
        "IOS2": "TK",
        "IOS3": "TKL",
        "code": "+690"
      },
      {
        "EnName": "Tonga",
        "Name": "汤加",
        "IOS2": "TO",
        "IOS3": "TON",
        "code": "+676"
      },
      {
        "EnName": "Trinidad and Tobago",
        "Name": "特立尼达和多巴哥",
        "IOS2": "TT",
        "IOS3": "TTO",
        "code": "+1868"
      },
      {
        "EnName": "Tunisia",
        "Name": "突尼斯",
        "IOS2": "TN",
        "IOS3": "TUN",
        "code": "+216"
      },
      {
        "EnName": "Türkiye",
        "Name": "土耳其",
        "IOS2": "TR",
        "IOS3": "TUR",
        "code": "+90"
      },
      {
        "EnName": "Turkmenistan",
        "Name": "土库曼斯坦",
        "IOS2": "TM",
        "IOS3": "TKM",
        "code": "+993"
      },
      {
        "EnName": "Turks and Caicos Islands",
        "Name": "特克斯和凯科斯群岛",
        "IOS2": "TC",
        "IOS3": "TCA",
        "code": "+1649"
      },
      {
        "EnName": "Tuvalu",
        "Name": "图瓦卢",
        "IOS2": "TV",
        "IOS3": "TUV",
        "code": "+688"
      },
      {
        "EnName": "U.S. Virgin Islands",
        "Name": "美属维尔京群岛",
        "IOS2": "VI",
        "IOS3": "VIR",
        "code": "+1340"
      },
      {
        "EnName": "Uganda",
        "Name": "乌干达",
        "IOS2": "UG",
        "IOS3": "UGA",
        "code": "+256"
      },
      {
        "EnName": "United Arab Emirates",
        "Name": "阿拉伯联合酋长国",
        "IOS2": "AE",
        "IOS3": "ARE",
        "code": "+971"
      },
      {
        "EnName": "United Kingdom",
        "Name": "英国",
        "IOS2": "GB",
        "IOS3": "GBR",
        "code": "+44"
      },
      {
        "EnName": "United States",
        "Name": "美国",
        "IOS2": "US",
        "IOS3": "USA",
        "code": "+1"
      },
      {
        "EnName": "Uruguay",
        "Name": "乌拉圭",
        "IOS2": "UY",
        "IOS3": "URY",
        "code": "+598"
      },
      {
        "EnName": "Uzbekistan",
        "Name": "乌兹别克斯坦",
        "IOS2": "UZ",
        "IOS3": "UZB",
        "code": "+998"
      },
      {
        "EnName": "Vanuatu",
        "Name": "瓦努阿图",
        "IOS2": "VU",
        "IOS3": "VUT",
        "code": "+678"
      },
      {
        "EnName": "Vatican",
        "Name": "梵帝冈",
        "IOS2": "VA",
        "IOS3": "VAT",
        "code": "+379"
      },
      {
        "EnName": "Venezuela",
        "Name": "委内瑞拉",
        "IOS2": "VE",
        "IOS3": "VEN",
        "code": "+58"
      },
      {
        "EnName": "Vietnam",
        "Name": "越南",
        "IOS2": "VN",
        "IOS3": "VNM",
        "code": "+84"
      },
      {
        "EnName": "Wallis and Futuna",
        "Name": "瓦利斯和富图纳群岛",
        "IOS2": "WF",
        "IOS3": "WLF",
        "code": "+681"
      },
      {
        "EnName": "Western Sahara",
        "Name": "西撒哈拉",
        "IOS2": "EH",
        "IOS3": "ESH",
        "code": "+212"
      },
      {
        "EnName": "Yemen",
        "Name": "也门",
        "IOS2": "YE",
        "IOS3": "YEM",
        "code": "+967"
      },
      {
        "EnName": "Zambia",
        "Name": "赞比亚",
        "IOS2": "ZM",
        "IOS3": "ZMB",
        "code": "+260"
      },
      {
        "EnName": "Zimbabwe",
        "Name": "津巴布韦",
        "IOS2": "ZW",
        "IOS3": "ZWE",
        "code": "+263"
      }
    ];
    let lo = ref(false);
    return {
      search,
      page4B,
      lo,
      phona,
      user,
      prodDeArr,
      prodIndex,
      prodAll,
      lan,
      nav, route,
      modules: [Mousewheel],
      solArr,
      prodArr,
      hover,
      page1p,
      page4p,
      page4T,
      menu,
      chosePro,
      solAlert,
      timer,
      st,
      pages,
      baseUrl
    }
  },
  methods: {
    gohome() {
      this.showProduct(false)
      this.setNav(0)
    },
    goSearch(v1, t) {
      this.search.data1 = ref([]);
      this.search.data2 = ref([]);
      this.search.open = false;
      if (t == 1) {
        this.solAlert.open = false;
        this.setNav(2)
        if (this.prodDeArr.length > 0) {
          this.prodDeArr.map((s) => {
            s.products.some((v, vi) => {
              if (v.productId == v1) {
                this.showProduct(true, s, vi);
                return v;
              }
            })
            return s;
          })
        }

      } else {
        this.chosePro.show = false;
        this.setNav(1);
        this.solArr.some((v) => {
          if (v.solutionId == v1) {
            this.openSol(v);
            return v;
          }
        })
      }
      this.search.v = '';
    },
    async onSearch(v, e) {
      e.stopPropagation();
      this.search.data1 = ref([]);
      this.search.data2 = ref([]);
      let d = await quest('/OpenApi/SolutionProductSearch', 'POST', {
        "pageIndex": 1,
        "pageSize": 5,
        "queryData": {
          "languageType": window.localStorage.getItem('lan'),
          "searchKeyword": this.search.v
        }
      }, {

      });
      if (d.data) {
        this.search.data1 = ref(d.data.products.dataList);
        this.search.data2 = ref(d.data.solutions.dataList);
      }
    },
    async out() {
      let d = await quest('/Account/Signout', 'POST', {}, {});
      if (d.data) {
        message.success(this.lan.value == '0' ? '退出成功' : 'Out success');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('acc');
        window.localStorage.removeItem('pas');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    encrypt(word) {
      let key = CryptoJS.enc.Utf8.parse(KEY);
      var iv = CryptoJS.enc.Utf8.parse(IV);

      let srcs = CryptoJS.enc.Utf8.parse(word);
      let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7

      });
      return encrypted.ciphertext.toString().toUpperCase();
    },
    pdf(e) {
      let url = this.$route.path + `#/pdf?path=` + e.fileUrl;
      let d = document.createElement('a');
      d.href = url;
      d.target = '_blank';
      document.body.appendChild(d);
      d.click();
      document.body.removeChild(d);
    },
    async down(e) {
      await quest('/Download/' + e.fileId, 'GET', {}, {});
      let url = this.baseUrl + e.fileUrl;
      let d = document.createElement('a');
      d.href = url;
      d.target = '_blank';
      document.body.appendChild(d);
      d.click();
      document.body.removeChild(d);
    },
    async handleOk() {
      if (this.user.type == 0) {
        let pas = (this.encrypt(this.user.login.password));
        let d = await quest('/Account/Login', 'POST', {
          reserved: '',
          data: {
            "phone": this.user.login.a + this.user.login.phone,
            "password": pas,
          }
        }, {});
        if (d.data) {
          message.success(this.lan == '0' ? '登录成功' : 'Login success');
          window.localStorage.setItem('token', d.data);
          window.localStorage.setItem('acc', this.user.login.a + this.user.login.phone);
          window.localStorage.setItem('pas', this.user.login.password);
          this.user.login.password = '';
          this.user.login.a = '';
          this.user.login.phone = '';
          this.user.open = false;
          window.location.reload();
        } else {
          message.error(this.lan == '0' ? '登录失败' : 'Login failed');
        }
      } else {
        if (this.user.reg.phone == '') {
          message.error(this.lan == '0' ? '请输入手机号' : 'Please enter phone number')
        } else if (this.user.reg.code == '') {
          message.error(this.lan == '0' ? '请输入验证码' : 'Please enter phone code')
        } else if (this.user.reg.name == '') {
          message.error(this.lan == '0' ? '请输入姓名' : 'Please enter phone name')
        } else if (this.user.reg.company == '') {
          message.error(this.lan == '0' ? '请输入公司' : 'Please enter phone company')
        } else if (this.user.reg.password == '') {
          message.error(this.lan == '0' ? '请输入密码' : 'Please enter phone password')
        } else if (this.user.reg.job == '') {
          message.error(this.lan == '0' ? '请输入职位' : 'Please enter phone job')
        }
        let pas = (this.encrypt(this.user.reg.password));
        let d = await quest('/Account/Register', 'POST', {
          reserved: '',
          data: {
            "company": this.user.reg.company,
            "name": this.user.reg.name,
            "job": this.user.reg.job,
            "phone": this.user.reg.a + this.user.reg.phone,
            "password": pas,
            "verificationCode": this.user.reg.code,
          }
        }, {});
        if (d.data) {
          message.success(this.lan == '0' ? '注册成功' : 'Reg success');
          setTimeout(() => {
            this.user.reg.company = '';
            this.user.reg.a = '+86';
            this.user.reg.phone = '';
            this.user.reg.name = '';
            this.user.reg.code = '';
            this.user.reg.job = '';
            this.user.reg.password = '';
            this.user.type = 0;
            this.user.time = 60;
            this.user.open = false;
            clearInterval(this.user.reg.st);
          }, 500);
        } else {
          message.error(this.lan == '0' ? '注册失败' : 'Reg failed');
        }
      }
    },
    setLan() {
      this.lan = (this.lan == '0' ? '1' : '0');
      window.localStorage.setItem('lan', this.lan);
      window.location.reload();
    },
    debounce(fn, delay = 1000) {
      return function () {
        const args = arguments
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          fn.apply(this, [...args])
        }, delay)
      }
    },
    scmove2(e) {
      let x = (document.getElementsByClassName('p4-1_b')[0].scrollTop);
      let x1 = x + 6 * (e.deltaY >= 0 ? 1 : -1);
      document.getElementsByClassName('p4-1_b')[0].scrollTo({
        top: x1,
      })
    },
    scmove3(e) {
      let x = (document.getElementsByClassName('product-t')[0].scrollTop);
      let x1 = x + 6 * (e.deltaY >= 0 ? 1 : -1);
      document.getElementsByClassName('product-t')[0].scrollTo({
        top: x1,
      })
    },
    scmove1(e) {
      if (Date.now() - this.st > 1000) {
        this.st = Date.now()
      } else {
        return;
      }
      if (this.nav == 3) {
        if (this.page4p == 1) {
          if (e.deltaY > 0) {
            this.debounce(this.setPon('page4p', 2))
          } else if (e.deltaY < 0) {
            this.debounce(this.setPon('page4p', 0))
          }
        }
        else if (this.page4p == 2) {
          if (e.deltaY > 0) {
            this.debounce(this.setPon('page4p', 3))
          } else if (e.deltaY < 0) {
            this.debounce(this.setPon('page4p', 1))
          }
        } else if (this.page4p == 0) {
          if (e.deltaY > 0) {
            this.page4p == 1
            this.debounce(this.setPon('page4p', 1))
          } else if (e.deltaY < 0) {
            this.debounce(this.setNav(2))
          }
        } else if (this.page4p == 3) {
          if (e.deltaY > 0) {
            this.debounce(this.setNav(4))
          } else if (e.deltaY < 0) {
            this.debounce(this.setPon('page4p', 2))
          }
        }
      }
    },
    scmove(e) {
      if (this.nav != 1 && (this.nav != 3 && (this.page4p != 2 || this.page4p != 2))) {
        if (Date.now() - this.st > 1000) {
          this.st = Date.now()
        } else {
          return;
        }
      }
      if (this.nav == 0) {
        if (this.page1p == 0 && e.deltaY >= 0) {
          this.debounce(this.page1p = 1)
        }
        else if (this.page1p == 1 && e.deltaY > 0) {
          this.debounce(this.setNav(1))
        }
        else if (this.page1p == 1 && e.deltaY < 0) {
          this.debounce(this.page1p = 0);
        }
      }
      else if (this.nav == 1) {
        let x = (document.getElementsByClassName('p3-pc')[0].scrollLeft);
        let x1 = x + 6 * (e.deltaY >= 0 ? 1 : -1);
        document.getElementsByClassName('p3-pc')[0].scrollTo({
          left: x1,
        })
      } else if (this.nav == 3) {
        // console.log(1)
        if (this.page4p == 1) {
          let x = (document.getElementsByClassName('p6-pc1')[0].scrollLeft);
          let x1 = x + 6 * (e.deltaY >= 0 ? 1 : -1);
          document.getElementsByClassName('p6-pc1')[0].scrollTo({
            left: x1,
          })
        }
        else if (this.page4p == 2) {
          let x = (document.getElementsByClassName('p6-pc')[0].scrollLeft);
          let x1 = x + 6 * (e.deltaY >= 0 ? 1 : -1);
          document.getElementsByClassName('p6-pc')[0].scrollTo({
            left: x1,
          })
        } else if (this.page4p == 3) {
          if (e.deltaY > 0) {
            this.debounce(this.setNav(4))
          } else if (e.deltaY < 0) {
            this.debounce(this.setPon('page4p', 2))
          }
        } else if (this.page4p == 0) {
          if (e.deltaY > 0) {
            this.page4p == 1
            this.debounce(this.setPon('page4p', 1))
          } else if (e.deltaY < 0) {
            this.debounce(this.setNav(2))
          }
        }
      }

    },
    solClose() {
      const newUrl = this.$route.path + `#/?page=${this.nav == 1 ? 'solution' : this.nav == 2 ? 'product' : this.nav == 3 ? 'about' : this.nav == 4 ? 'contact' : 'join'}`
      this.solAlert.open = false;
      this.$route.query = {};
      window.history.replaceState('', '', newUrl)
    },
    openSol(e) {
      if (!this.lo) {
        this.user.open = true
        return;
      }
      this.solAlert.data = e;
      const newUrl = this.$route.path + `#/?page=${this.nav == 1 ? 'solution' : this.nav == 2 ? 'product' : this.nav == 3 ? 'about' : this.nav == 4 ? 'contact' : 'join'}`
      this.solAlert.open = true;
      window.history.replaceState('', '', `${newUrl}&solutionId=${e.solutionId}`)
    },
    toSol(e) {
      this.chosePro.show = false;
      this.setNav(1);
      setTimeout(() => {
        this.solArr.some((v) => {
          if (v.solutionId == e.solutionId) {
            this.chosePro.show = false;
            this.openSol(v);
            return v;
          }
        })

      }, 500);
    },
    toPro(e) {
      this.solAlert.open = false;
      this.setNav(2)
      setTimeout(() => {
        this.prodDeArr.map((s) => {
          s.products.some((v, vi) => {
            if (v.productId == e.productId) {
              this.solAlert.open = false;
              this.showProduct(true, s, vi);
              return v;
            }
          })
          return s;
        })

      }, 500);

    },
    showProduct(e, v, c1) {
      const newUrl = this.$route.path + `#/?page=${this.nav == 1 ? 'solution' : this.nav == 2 ? 'product' : this.nav == 3 ? 'about' : this.nav == 4 ? 'contact' : 'join'}`
      this.$route.query = {};
      if (e) {
        if (v.products.length <= 0) {
          message.info(this.lan == '0' ? '该系列暂无产品' : 'There are no products in this series')
          return;
        }
        this.chosePro.data = v.products[c1 ? c1 : 0];
        this.chosePro.index = 0;
        window.history.replaceState('', '', `${newUrl}&productId=${v.products[0].productId}`);
        this.chosePro.fa = v;
      } else {
        window.history.replaceState('', '', `${newUrl}`);

      }
      this.chosePro.show = e;
    },
    choseProduct(e) {
      this.chosePro.data = e;
      this.chosePro.index = 0;
      const newUrl = this.$route.path + `#/?page=${this.nav == 1 ? 'solution' : this.nav == 2 ? 'product' : this.nav == 3 ? 'about' : this.nav == 4 ? 'contact' : 'join'}`

      window.history.replaceState('', '', `${newUrl}&productId=${e.productId}`)
    },
    menuopen(e) {
      this.menu = e;
    },
    setPon(c, v) {
      this[c] = v;
    },
    hoverThis(e) {
      this.hover = e;
    },
    toPage(e) {
      let l = Math.ceil(this.prodDeArr.length / this.pages);
      this.prodAll = l;
      this.prodArr = this.prodDeArr.slice(this.pages * e, this.pages * e + this.pages);
      this.prodIndex = e;
    },
    async getTime() {

      let v = await quest('/DataDic/GetByKey', 'POST', {
        "data": {
          "languageType": Number(localStorage.getItem('lan')),
          "key": "history"
        }
      }, {});
      if (v.data) {
        let d1 = v.data.value;
        if (d1 == '') {
          d1 = [];
        } else {
          d1 = JSON.parse(d1)
        }
        this.page4T = ref(d1);
      }
    },
    async getPic() {

      let v = await quest('/DataDic/GetByKey', 'POST', {
        "data": {
          "languageType": Number(localStorage.getItem('lan')),
          "key": "honors"
        }
      }, {});
      if (v.data) {
        let d1 = v.data.value;
        if (d1 == '') {
          d1 = [];
        } else {
          d1 = JSON.parse(d1)
        }
        this.page4B = ref(d1);
      }
    },
    async getProd(v) {
      this.prodDeArr = [];
      this.prodArr = [];
      let d = await quest('/OpenApi/ProductSerieProductList', 'POST', {
        data: {
          "languageType": this.lan
        }
      }, {});

      let d1 = await quest('/OpenApi/ProductSerieProductList', 'POST', {
        data: {
          "languageType": 1
        }
      }, {});
      this.prodDeArr = d.data;

      d1.data.map((c, ci) => {
        this.prodDeArr[ci].en = c.name
        return c;
      })
      console.log(this.prodDeArr)

      let l = Math.ceil(this.prodDeArr.length / this.pages);
      this.prodAll = l;
      this.prodIndex = 0;
      if (v) {

      } else {
        this.prodArr = d.data.slice(0, this.pages);
      }

    },
    async getSol() {
      this.solArr = [];
      let d = await quest('/OpenApi/SolutionProductList', 'POST', {
        data: {
          "languageType": this.lan
        }
      }, {});
      this.solArr = d.data;

    },
    async getCode() {
      if (this.user.reg.time != 60) {
        return;
      }
      if (this.user.reg.phone == '') {
        message.error(this.lan == '0' ? '请输入手机号' : 'Please enter phone number');
        return;
      }
      let d = await quest('/SMS/SendRegisterVerificationCode', 'POST', {
        data: {
          phone: this.user.reg.a + this.user.reg.phone
        }
      }, {});
      if (!d.data) {
        message.error(this.lan == '0' ? '发送失败' : 'Send failed');
        return;
      }
      this.user.reg.st = setInterval(() => {
        if (this.user.reg.time == 0) {
          clearInterval(this.user.reg.st);
          this.user.reg.time = 60;
        }
        this.user.reg.time--;
      }, 1000);
    },
    setNav(e) {
      this.menu = false;
      this.nav = e;
      this.swiper.slideTo(this.nav, 250, false);
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    change() {
      this.nav = this.swiper.activeIndex;
      // this.page1p = 0;
      // this.page4p = 0;

      if (this.nav) {
        let s1 = null;
        if (this.$route.query.solutionId) {
          s1 = setTimeout(() => {
            if (this.solArr.length > 0) {
              this.solArr.some((v) => {
                if (v.solutionId == this.$route.query.solutionId) {
                  this.openSol(v);
                  return v;
                }
              })
              clearTimeout(s1);
            }
          }, 100);
          this.showProduct(false);
        } else if (this.$route.query.productId) {
          s1 = setTimeout(() => {
            if (this.prodDeArr.length > 0) {
              this.prodDeArr.map((s) => {
                s.products.some((v, vi) => {
                  if (v.productId == this.$route.query.productId) {
                    this.showProduct(true, s, vi);
                    clearTimeout(s1);
                    return v;
                  }
                })
                return s;
              })
            }
          }, 100);
        } else {
          this.showProduct(false);
          if (this.nav == 4) {
            this.setPon('page4p', 3)
          } else if (this.nav == 2) {
            this.setPon('page4p', 0)
          }
          const newUrl = this.$route.path + `#/?page=${this.nav == 1 ? 'solution' : this.nav == 2 ? 'product' : this.nav == 3 ? 'about' : this.nav == 4 ? 'contact' : 'join'}`
          window.history.replaceState('', '', newUrl)
        }
      } else {
        const newUrl = this.$route.path;
        window.history.replaceState('', '', '#' + newUrl)
      }
    },
    async isLog() {
      if (window.localStorage.getItem('token')) {
        let pas = (this.encrypt(window.localStorage.getItem('pas')));
        let d = await quest('/Account/Login', 'POST', {
          reserved: '',
          data: {
            "phone": window.localStorage.getItem('acc'),
            "password": pas,
          }
        }, {});
        console.log(d);
        if (d.data) {
          this.lo = true;
          window.localStorage.setItem('token', d.data);
          setTimeout(() => {
            this.isLog();
          }, 1000 * 60 * 30);
        } else {
          this.lo = false;
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('acc');
          window.localStorage.removeItem('pas');
        }
      }
    }
  },
  mounted() {
    this.isLog();
    if (!this.lan) {
      window.localStorage.setItem('lan', '0');
      this.lan = window.localStorage.getItem('lan');
    }
    this.pages = 5;
    if (document.body.clientWidth <= 768) {
      this.pages = 4;
    }
    this.getSol();
    this.getProd();
    this.getTime();
    this.getPic();
    window.addEventListener('resize', () => {
      this.pages = 5;
      if (document.body.clientWidth <= 768) {
        this.pages = 4;
      }
      this.getProd();
    })
    var map = new BMap.Map("map");
    var point = new BMap.Point(118.547439, 31.97954);
    map.centerAndZoom(point, 13);
    var marker = new BMap.Marker(point);
    map.addOverlay(marker);
  }
}
</script>
<style>
input {
  border-radius: 0 !important;
}

.ant-input-group .ant-input-group-addon {
  border-radius: 0 !important;
}

.ant-modal .ant-modal-content {
  border-radius: 0 !important;
}

.ant-btn-primary {
  background: #3639b8 !important;
  border-radius: 0 !important;
  height: 33px !important;
}

.ant-btn-default {
  border-radius: 0 !important;
  height: 33px !important;
}
</style>
<style scoped lang="less">
input {
  border-radius: 0 !important;
}

.search-m {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000095;

  &_g {
    width: 50%;
  }

  &_b {
    background: #fff;
    color: #333;

    div {
      cursor: default;
      font-size: 1rem;
      line-height: 2;
      transition: 0.1s;

      &:hover {
        color: #3639b8;
      }
    }
  }

  &_l {
    width: 100%;
  }
}

.downlist {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  .div {
    color: #333;
    width: 50%;
    min-height: 2rem;
    line-height: 1.75;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.log-f {
  width: 100%;
  display: flex;
  flex-direction: row;

  .log-l {
    margin-right: 1rem;
    cursor: pointer;

    &.act {
      color: #3639b8;
      border-bottom: 0.2rem solid #3639b8;
    }
  }
}

.pagep {
  display: none;
  width: 3rem;
  height: 3rem;
  position: fixed;
  top: 50%;
  margin-top: -1.5rem;
  background: #00000050;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  z-index: 105;

  svg {
    width: 60%;
    height: 60%;
  }

  &.l {
    left: 1rem;
  }

  &.r {
    right: 1rem;
  }
}

.sol {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #00000080;

  display: flex;
  justify-content: center;
  align-items: center;

  &_close {
    display: none;
  }

  &_pro {
    height: 6rem;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    background: #000050;
    box-sizing: border-box;
    padding-top: 0.5rem;

    span {
      color: #fff;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 0.75rem;
    }

    &_l {
      display: inline-block;
      width: 8rem;
      height: calc(100% - 0.5rem);
      margin: 0 0.25rem;
      background: #ffffff20;
      box-sizing: border-box;
      padding: 0.5rem;
      position: relative;

      img {
        width: 100%;
        height: calc(100% - 1rem);
        object-fit: contain;
      }

      p {
        font-size: 0.75rem !important;
        height: 1rem !important;
        line-height: 1rem !important;
        margin: 0;
        color: #fff !important;
position: absolute !important;
bottom: 0 !important;

white-space: nowrap; //不换行
      text-overflow: ellipsis;//超出部分使用省略号
      overflow: hidden; //超出部分隐藏
      word-break: break-all;//设置英文，数字不换行
display: block !important;

      }

    }
  }

  &_img {
    width: 50%;
    height: 80%;

    background: #ffffff;

    p {
      font-size: 1rem;
      color: #333;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    &_t {
      overflow-y: auto;
      width: 100%;
      height: calc(100% - 6rem);
    }

    img {
      width: 100%;
    }
  }
}

::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;

}

@keyframes treeb {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes mouse {
  0% {
    top: -0.25vw;
    opacity: 0;
  }

  33% {
    top: 0vw;
    opacity: 1;
  }

  100% {
    top: 0.75vw;
    opacity: 0;
  }
}

.mouse {
  width: 2vw;
  height: 2vw;
  position: fixed;
  right: 4rem;
  bottom: 1rem;

  &::before {
    position: absolute;
    content: '';
    width: 2vw;
    height: 1px;
    left: -2vw;
    top: 1vw;
    background: rgba(255, 255, 255, 0.5);
  }

  &::after {
    position: absolute;
    content: '';
    width: 2vw;
    height: 1px;
    right: -2vw;
    top: 1vw;
    background: rgba(255, 255, 255, 0.5);
  }

  svg {
    fill: #fff;
    width: 2vw;
    height: 2vw;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: middle;

    &:nth-child(2) {
      animation: mouse 1.5s infinite ease-in-out;
    }
  }
}

:focus {
  outline: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(139, 175, 247, 0.5);
  background-clip: padding-box !important;
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.group {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  background-color: #3639b8;
  background-image: url('/public/images/bg.png');
  background-size: 0.75rem 0.75rem;
  background-repeat: repeat;

  .phone {
    display: none !important;
  }

  .pc {
    display: block;
  }

  .page-in {
    position: absolute;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 500px;
    top: 50%;
    width: 100%;
    opacity: 0;
    transition: 0.1s;
    transform: translate(0, -50%);

    &.inner {
      max-height: 600px;
    }

    h3 {
      font-size: 1.5rem;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;

      h4 {
        margin: 0;
        position: absolute;
        top: 100%;
        width: 15rem;
        text-align: left;
        font-size: 1rem;
      }
    }

    &_content {
      width: 100%;
      height: 100%;
      position: relative;

      &.max {
        max-width: 1024px;
      }

      display: flex;
      flex-direction: row;
      // overflow: hidden;
    }

    &.act1 {
      opacity: 1;

      .p1 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }

      .p2 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }

      .p5 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }

      .p6 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }
    }

    &.act2 {
      opacity: 1;

      .p6-1 {
        opacity: 1;
      }

      .p1-1 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }

      .p1-2 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }
    }

    &.act3 {
      opacity: 1;

      .p6-2 {
        opacity: 1;
      }
    }

    &.act4 {
      opacity: 1;

      .p6-3 {
        opacity: 1;
      }
    }

    &.act {
      opacity: 1;
      z-index: 3;


      .p8 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }

      .p9 {
        transform: translate(0, 0);
        opacity: 1;
        transition-delay: 0.5s;
      }
    }

    .p3 {
      position: absolute;
      top: 4rem;
      left: 0;
      right: 0;
      bottom: 1rem;
      text-align: center;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      box-sizing: border-box;
      padding-bottom: 0.5rem;
      padding-right: 0.5rem;

      .p3l {
        width: 40rem;
        height: 100%;
        display: inline-block;
        overflow: hidden;
        position: relative;
        opacity: 0.8;
        transition: 0.1s;

        &:hover {
          opacity: 1;
        }

        p {
          margin: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          font-size: 0.8rem;
          line-height: 3;
          color: #fff;
          background: #00000070;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &_l {
        height: 100%;
        width: 20rem;
        display: inline-block;
        overflow: hidden;
        margin-left: 0.5rem;

        .p3l {
          height: calc(50% - 0.25rem);
          width: 20rem;
          float: left;

          &:nth-child(2) {
            margin-top: 0.5rem;
          }
        }
      }


    }

    .p5 {
      width: 40%;
      height: 100%;
      background: url('/public/images/page3-1.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(-100%, 0);
      transition: 0.5s;
    }

    .p6 {
      width: 60%;
      height: calc(100% - 1rem);
      background: url('/public/images/page3-2.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(100%, 0);
      transition: 0.5s;

      &.e1 {
        background: url('/public/images/page3-2e.png') center center no-repeat;
        background-size: contain;
      }

    }

    .p6-1 {
      width: 100%;
      position: absolute;
      height: calc(100% - 5rem);
      opacity: 0;
      margin-top: 2rem;
      overflow-x: auto;
      z-index: 2;

      div {
        p {
          &:nth-child(1) {
            color: #2793c8;
            font-weight: bold;
            margin-bottom: 0.25rem;
          }
        }
      }

      .line {
        height: 0.25rem;
        position: relative;
        top: 50%;
        margin-top: -0.125rem;
        background: #808afa;
        transform: scale(0, 1);
      }

      .line1 {
        position: absolute;
        top: 50%;
        width: 15rem;
        box-sizing: border-box;
        padding-top: 1rem;

        span {
          width: 0.2rem;
          height: calc(100% + 1rem);
          content: '';
          background: #808afa;
          position: absolute;
          left: -1rem;
          transform: scale(1, 0);

          &:nth-child(4) {
            width: 100%;
            height: 0.2rem;
            transform: scale(0, 1);

            &::before {
              content: '';
              position: absolute;
              width: 0.5rem;
              right: -0.25rem;
              background: #808afa;
              border-radius: 100%;
              top: 50%;
              height: 0.5rem;
              margin-top: -0.25rem;
            }
          }

        }
      }

      p {
        margin: 0;
        font-size: 0.75rem;
        text-align: left;
        opacity: 0;
      }
    }

    .p6-2 {
      width: 100%;
      position: absolute;
      height: calc(100% - 5rem);
      opacity: 0;
      margin-top: 2rem;
      text-align: center;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      box-sizing: border-box;
      z-index: 2;

      div {
        width: auto;
        height: 50%;

        display: inline-block;
        overflow: hidden;
        margin: 0 1rem;
        transform: translate(20%, 0);
        opacity: 0;
        margin-top: 6rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .p6-3 {
      width: 100%;
      position: absolute;
      height: calc(100% - 1rem);
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.25s;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .p8 {
      width: 40%;
      height: 100%;
      background: url('/public/images/page5-1.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(-100%, 0);
      transition: 0.5s;
    }

    .p9 {
      width: 60%;
      height: 100%;
      background: url('/public/images/page5-2.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(100%, 0);
      transition: 0.5s;
      position: relative;

      &.e1 {
        background: url('/public/images/page5-2e.png') center center no-repeat;
        background-size: contain;
      }

      .p9btn {
        background: #6267f3;
        width: 10rem;
        height: 3rem;
        position: absolute;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        margin-left: -5rem;
        bottom: 15%;
        border-radius: 10px;
        cursor: pointer;

        a {
          color: #fff !important;
          text-decoration: none;

        }
      }

    }

    .p-pon {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: row;

      z-index: 2;

      &_l {
        width: 2rem;
        height: 0.3rem;
        background: #ffffff50;
        margin: 0 0.5rem;
        border-radius: 2px;
        cursor: pointer;

        &.act {
          background: #fff;
        }
      }
    }

    .p1 {
      width: 40%;
      height: 100%;
      background: url('/public/images/page1-1.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(-100%, 0);
      transition: 0.5s;
      position: absolute;
      left: 0;

      &.e1 {
        background: url('/public/images/page1-1e.png') center center no-repeat;
        background-size: contain;
      }
    }

    .p2 {
      width: 60%;
      height: 100%;
      background: url('/public/images/page1-2.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(100%, 0);
      transition: 0.5s;
      position: absolute;
      right: 0;

    }

    .p1-1 {
      width: 40%;
      height: 100%;
      background: url('/public/images/page1-3.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(-100%, 0);
      transition: 0.5s;
      position: absolute;
      left: 0;

      &.e1 {
        background: url('/public/images/page1-3e.png') center center no-repeat;
        background-size: contain;
      }
    }

    .p1-2 {
      width: 80%;
      height: 100%;
      background: url('/public/images/page1-4.png') center center no-repeat;
      background-size: contain;
      opacity: 0;
      transform: translate(100%, 0);
      transition: 0.5s;
      position: absolute;
      right: 0;

      &.e1 {
        background: url('/public/images/page1-4e.png') center center no-repeat;
        background-size: contain;
      }
    }

    .tool {
      position: absolute;
      bottom: 0;
      right: 1.75rem;
      height: 3rem;
      display: flex;
      flex-direction: row;

      &_l {
        width: 3rem;
        height: 3rem;
        background: url('/public/images/left.png') center center no-repeat;
        background-size: 100% 100%;

        &.dis {
          opacity: 0.5;
        }

        &.r {
          background: url('/public/images/right.png') center center no-repeat;
          background-size: 100% 100%;
          margin-left: 0.5rem;
        }
      }
    }

    .p7 {
      position: absolute;
      top: 6rem;
      left: 0;
      right: 4rem;
      bottom: 0;
      border-right: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .map {
        width: 10rem;
        height: 10rem;
        max-width: 80%;
        object-fit: contain;
        max-height: calc(100% - 6rem);
        margin: 0 auto;
        margin-top: 1rem;
      }

      .contact {
        width: 8rem;
        height: 8rem;
        max-width: 80%;
        object-fit: contain;
        max-height: calc(100% - 6rem);
        margin-top: 2rem;
      }

      p {
        margin: 0;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
      }

      .pl1 {
        color: #333;
      }

      .pl2 {
        color: #808080;
      }

      &_l {
        width: calc(33.33% - 1rem);
        height: 100%;
        background: #fff;
        border-radius: 1%;
        position: relative;
        box-sizing: border-box;
        padding: 3rem 1rem;

        &_t {
          background: #d6e6fe;
          height: 4rem;
          width: 4rem;
          border-radius: 100%;
          position: absolute;
          top: -2rem;
          left: 50%;
          margin-left: -2rem;

          img {
            width: 60%;
            height: 60%;
            margin: 20%;
            object-fit: contain;
          }
        }
      }
    }

    .p4-2 {
      position: absolute;
      top: 0;
      height: 4.5rem;
      left: 0;
      right: 0;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: hidden;
      box-sizing: border-box;
      text-align: center;

      &_l {
        height: 3.5rem;
        margin-right: 0.5rem;
        display: inline-block;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0.1rem;
        padding: 0 0.5rem;
        box-sizing: border-box;

        img {
          width: 100%;
          height: calc(100% - 2rem);
          margin-top: 0.5rem;
          object-fit: contain;
        }

        p {
          margin: 0;
          text-align: center;
          font-size: 0.75rem;
          color: #fff;
          line-height: 1rem;
        }
      }
    }

    .p4-1 {
      position: absolute;
      top: 0;
      left: 0;
      right: 1.25rem;
      bottom: 0;
      border-right: 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: left;
      background: #fff;
      border-radius: 1rem;

      .sol-list {
        width: 10rem;
        height: 6rem;
        display: inline-block;
        position: relative;
        border-radius: 0.2rem;
        overflow: hidden;
        margin: 3rem 0 0 1rem;
        cursor: default;
        transition: 0.1s;

        &:hover {
          transform: scale(1.1, 1.1);
        }

        &::before {
          content: '';
          background: rgba(0, 0, 0, 0.5);
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        p {
          position: absolute;
          font-size: 1rem;
          color: #fff;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          margin: 0;
          text-align: center;
          font-weight: bold;
          box-sizing: border-box;
          padding: 1rem;
        }
      }

      img {
        width: 100%;
        height: auto;
      }

      &_b {
        position: absolute;
        top: 3rem;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        text-align: center;

        p {
          font-size: 1rem;
          color: #999;
          text-align: center;
          width: 100%;
          margin-top: 5rem;
        }
      }

      .return {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: #3639b850;
        z-index: 1;

        svg {
          width: 80%;
          height: 80%;
        }
      }

      &_t {
        width: 100%;
        height: 3rem;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        box-sizing: border-box;
        text-align: center;
        // border-bottom: 2px solid #eaebec;
        padding-left: 2rem;
        position: relative;



        &::-webkit-scrollbar {
          width: 0.15rem !important;
          height: 0.25rem !important;

        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.25);
          background-clip: padding-box !important;
          border-radius: 0;
        }

        &_l {
          display: inline-block;
          font-size: 1rem;
          padding: 0 0.5rem;
          text-align: center;
          position: relative;
          line-height: 3rem;
          color: #333;
          cursor: default;

          &::before {
            display: none;
            position: absolute;
            left: 30%;
            right: 30%;
            height: 2px;
            content: '';
            bottom: 0;
            background: #e7524f;
          }

          &.active {
            color: #e7524f;
            font-weight: bold;

            &::before {
              display: flex;
            }
          }
        }
      }
    }

    .p4 {
      position: absolute;
      top: 4rem;
      left: 0;
      right: 0;
      bottom: 4rem;
      border-right: 0;
      box-sizing: border-box;
      overflow: hidden;
      text-align: left;

      img {
        object-fit: contain;
        width: calc(100% - 2rem);
        max-height: calc(100% - 8rem);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 1rem;
      }

      p {
        margin: 0;
      }


      &_l {
        display: inline-block;
        width: 18%;
        height: 100%;
        background: linear-gradient(to top, rgba(46, 119, 175, 0.9) 5%, rgba(54, 57, 184, 1));
        border-right: 1px solid #464abb;
        border-top: 1px solid #464abb;
        border-bottom: 1px solid #464abb;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        transition: 0.25s;

        padding: 1rem;

        .p4-t {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          opacity: 0;
          transition: 0.25s;

          p {
            font-size: 1rem;
            font-weight: bold;

            &:nth-child(2) {
              font-weight: normal;
              font-size: 0.75rem;
            }
          }
        }

        &:hover {
          .active {
            opacity: 1;
          }
        }

        .active {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #00000070;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.1s;

          .btn {
            border: 1px solid #fff;
            border-radius: 5px;
            font-size: 1rem;
            box-sizing: border-box;
            cursor: default;
            padding: 0.25rem 0.75rem;
          }
        }

        .info {
          position: absolute;
          bottom: 0;
          font-size: 0.75rem;
          padding-bottom: 0.75rem;
          line-height: 1.5;
          padding-right: 1rem;
          transition: 0.25s;

          p {
            &:nth-child(2) {
              font-size: 0.5rem;
            }
          }

          &::before {
            width: 4rem;
            height: 2px;
            background: #fff;
            content: '';
            top: -1rem;
            left: 0;
            display: inline-block;
            position: absolute;
          }
        }

        &.act {
          width: 28%;
          background: linear-gradient(to top, #2e76af, #4a4dc0);

          .p4-t {
            opacity: 1;
          }
        }

        &:nth-child(1) {
          border-left: 1px solid #464abb;
        }
      }


      //   padding: 1vw;
      // height: 100%;
      // width: 19%;
      // box-sizing: border-box;
      // background: linear-gradient(to top, rgba(46, 119, 175, 0.9) 5%, rgba(54, 57, 184, 1));
      // border-left: 1px solid #464abb;
      // border-top: 1px solid #464abb;
      // border-bottom: 1px solid #464abb;
      // text-align: center;
      // position: relative;
      // transition: 0.25s;
      // float: left;
    }
  }

  .content {
    position: absolute;
    top: 4.5rem;
    bottom: 4.75rem;
    left: 12rem;
    right: 0;
    z-index: 2;
  }

  .content-swiper {
    width: 100%;
    height: 100%;
  }

  .product-t {
    left: 1rem;
    top: 50%;
    transform: translate(calc(-150% - 1rem), -50%);
    position: absolute;
    z-index: 2;
    transition: 0.1s;
    overflow-y: auto;
    max-height: 40%;
    width: 11rem;

    &.s {
      transform: translate(0, -50%);
    }

    &_l {
      font-size: 1rem;
      text-align: left;
      margin: 1.5rem 0;
      padding-left: 1.5rem;
      position: relative;
      transition: 0.25s;
      cursor: default;

      &::before {
        width: 1rem;
        height: 1rem;
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.5rem;
        border-radius: 100%;
        background: #6267f3;
      }

      p {
        color: #6267f3 !important;
      }

      &.sm {
        font-size: 0.75rem;

        &::before {
          transform: scale(0.8, 0.8);
        }
      }

      &.active {
        p {
          color: #fff !important;
        }

        &::before {
          background: #fff;
        }
      }
    }
  }

  .nav {
    left: 1.25rem;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    transition: 0.1s;

    &.pro {
      left: 0;
      transform: translate(-100%, -50%);
    }

    &_l {
      font-size: 1rem;
      color: #ffffff70;
      text-align: left;
      margin: 1.2rem 0;
      padding-left: 1.25rem;
      position: relative;
      transition: 0.25s;
      cursor: pointer;

      &::before {
        content: '';
        height: 60%;
        position: absolute;
        width: 0.18rem;
        background: #fff;
        left: 0;
        top: 20%;
        transform: scale(0, 0);
        transition: 0.25s;
        opacity: 0;
      }

      &.act {
        color: #ffffff;
        font-weight: bold;

        &::before {
          transform: scale(1, 1);
          opacity: 1;
        }
      }
    }
  }

  &::before {
    border-radius: 100%;
    width: 25rem;
    height: 25rem;
    position: absolute;
    left: -14rem;
    top: -15rem;
    z-index: 1;
    content: '';
    background: rgba(128, 138, 250, 0.5);
  }

  &::after {
    background: linear-gradient(to right bottom, #2d33ae, rgba(255, 255, 255, 0));
    border-radius: 100%;
    width: 35rem;
    height: 35rem;
    position: absolute;
    left: 12rem;
    top: 12rem;
    z-index: 1;
    content: '';
    transform: scale(1) translate3d(0, 0, 0) rotate3d(0deg);
    border-top-right-radius: 50%;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    padding: 0.5rem 2rem;
    z-index: 2;

    p {
      margin: 0;
      line-height: 1.75;
    }

    a {
      color: #fff;
      font-weight: bold;
    }
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1.25rem;
    z-index: 2;

    .logo {
      width: 7rem;
      height: 2.1rem;
      background: url('/public/images/logo.png') center center no-repeat;
      background-size: 100% 100%;
      z-index: 9999;
      cursor: pointer;
    }

    .act {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_l {
        width: 2.1rem;
        height: 2.1rem;
        background: #ffffff20;
        border-radius: 100%;
        margin-left: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        transition: 0.25s;
        cursor: default;

        &:hover {
          background: #ffffff50;
        }

        &:nth-child(1) {
          svg {
            width: 60%;
            height: 60%;
          }
        }

        svg {
          width: 50%;
          height: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .search-m_g {
    width: 80%;
  }

  .sol {
    &_close {
      width: 2.5rem;
      height: 2.5rem;
      position: fixed;
      top: 1rem;
      right: 1rem;
      z-index: 999;
      display: flex;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &_img {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      img {
        width: 100%;
      }
    }
  }

  .mouse {
    display: none;
  }

  .group {
    .title {
      padding: 1.25rem 0.5rem;
    }

    .act1 {
      .pagep {
        display: flex;
      }
    }

    .act2 {
      .pagep {
        display: flex;
      }
    }

    .phone {
      display: block !important;

      &.act_l {
        display: flex !important;
      }
    }

    .act3 {
      .pagep {
        display: flex;
      }
    }

    .act4 {
      .pagep {
        display: flex;
      }
    }

    .pc {
      display: none;
    }

    .nav {
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.9);
      transform: translate(-100%, -50%);
      transition: 0.25s;
      z-index: 5;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      padding-left: 0;

      &.open {
        transform: translate(0, -50%);
      }
    }

    .page-in {
      max-height: none;

      .p4-1 {
        left: 0;
        top: 5rem;
        right: 0;
        border-radius: 10px;
      }

      .p1 {
        width: 100%;
        height: 74%;
        margin-top: -5rem;
      }

      .p2 {
        width: 100%;
        height: 60%;
        top: 40%;
      }

      .p1-1 {
        width: 100%;
        height: 75%;
        margin-top: -5rem;
      }

      .p1-2 {
        width: 100%;
        height: 60%;
        top: 35%;
      }

      .p5 {
        width: 100%;
        height: 30%;
        top: 10%;
        position: absolute;
      }

      .p6 {
        width: 100%;
        height: 60%;
        top: 35%;
        position: absolute;
      }

      .p8 {
        width: 100%;
        height: 30%;
        top: 10%;
        position: absolute;
      }

      .p9 {
        width: 100%;
        height: 60%;
        top: 35%;
        position: absolute;
      }

      .p7 {
        overflow-y: auto;
        right: 0;
        display: inline-block;

        .p7_l {
          width: 20rem;
          height: 25rem;
          margin: 0 auto;
          margin-top: 4rem;

        }
      }

      .p4_l {
        width: 50% !important;
        height: 50% !important;
        margin-bottom: 0;
        box-sizing: border-box;
        float: left;
      }

      .p3 {
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 0;

        &_l {
          width: 100%;
          height: 12rem;
          display: flex;
          margin-left: 0;
          margin-right: 0;
          float: left;
          margin-bottom: 1rem;

          // &:nth-child(2n - 1) {
          //   margin-right: 0.5rem;
          // }
        }

        .p3l {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .content {
      left: 0.5rem;
      right: 0.5rem;
    }

  }

}
</style>
