import api from './url';
const baseUrl = api.api;
import { useRouter } from "vue-router";
import router from '../router/index';
const queryString = (params) => {
    if (Object.keys(params).length > 0) {
        return '?' + Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')
    }
    return ' '
};
const quest = async (url, method, data, headers, token) => {
    try {
        let options = {
            method: method || "GET",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': window.localStorage.getItem('token'),
                // ...headers
            },
            body: JSON.stringify(data) || JSON.stringify({}),
            mode: 'cors',
        };
        if (options.method.toUpperCase() === "GET") {
            url = baseUrl + url + queryString(options.body)
            delete (options).body
        } else {
            url = baseUrl + url
        }
        const res = await fetch(url, options)

        if (res.status == 401) {
            // router.replace('/login');
            console.log('暂无权限')
        }
        const pageData = await res.json()
        if (pageData.code == 1) {
            return Promise.resolve(pageData)
        } else {
            return new Error(pageData.message)
        }
    } catch (err) {
        return new Error(err)
    }
}
export default quest;