<template>
    <pdf :src="`${baseUrl}${route.query.path}`" :page="page">
    </pdf>
    <div class="page">
        <div class="page_l" @click="page <= 1 ? 0 : page--"></div>
        <div class="page_l" @click="page >= pageA ? pageA : page++"></div>
    </div>
</template>

<script>
import pdf from 'pdfvuer'
import 'pdfjs-dist/build/pdf.worker.entry' // not needed since v1.9.1
import api from '../api/url';
import { ref, watchEffect, computed, getCurrentInstance, watch } from 'vue'
import { useRoute } from 'vue-router';
export default {
    components: {
        pdf
    },
    setup() {
        let baseUrl = ref(api.api);
        const route = useRoute();
        let page = ref(1);
        let pageA = ref(0);
        return {
            baseUrl,
            route,
            page,
            pageA
        }
    },
    mounted() {
        console.log(`${this.baseUrl}${this.route.query.path}`);


        self.pdfdata = pdf.createLoadingTask(`${this.baseUrl}${this.route.query.path}`, { responseType: "blob" });
        self.pdfdata.then(p => {
            this.pageA = p.numPages;
        })
    }
}
</script>
<style lang="less" scoped>
.page {
    position: fixed;
    bottom: 1rem;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    background: #00000080;
    border-radius: 4rem;

    &_l {
        width: 2rem;
        height: 2rem;
        margin: 0 0.5rem;

        border-radius: 100%;
        background: url('/public/images/left.png') center center no-repeat;
        background-size: 100% 100%;

        &:nth-child(2) {
            background: url('/public/images/right.png') center center no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>