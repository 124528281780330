import { createRouter, createWebHashHistory } from 'vue-router';
import Index from '../components/Index.vue';
import Pdf from '../components/Pdf.vue';
const routes = [
    {
        path: '/',
        name: '首页',
        component: () => import("../components/Content.vue"),
    }, {
        path: "/pdf",
        name: '预览',
        component: () => import("../components/Pdf.vue"),
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router;